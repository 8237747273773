import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Loader } from "../../Components/Loader/loader";

import styles from './blogList.module.scss'
import BackgroundSlider from "../../Components/Elements/BackgroundSlider/backgroundSlider";
import Medium from "../../Components/Elements/medium/medium";
import { Helmet } from "react-helmet";
import { translationMix } from "../../Components/Helpers/translationMix";
import { getBlogMainPage, searchBlog } from "../../apiCalls/blogApiCalls";



function BlogList() {
    const [data, setData] = useState(null)
    const [dataList, setDataList] = useState(null)
    const [searchText, setSearchText] = useState(null)
    const { lang } = useParams();

    useEffect(() => {
        getBlogMainPage().then(res => {
            setData(res)
        })
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let readSearchText = urlParams.get('searchText');
        setSearchText(readSearchText);
        searchBlog(readSearchText).then(res => {
            setDataList(res)
        })
    }, []);


    return (
        <>
            <Helmet>
                <title>{lang === 'pl' ? 'Bizserver - blog o najnowszych technologiach serwerowych' : lang === 'en' ? 'Bizserver - blog about the latest server technologies' : 'Bizserver - Blog über die neuesten Servertechnologien'}</title>
                <meta name="description" content={"Bizserver - blog o najnowszych technologiach serwerowych"} />
            </Helmet>
            <Loader data={data && dataList} />
            {data && dataList ?
                <>
                    <BackgroundSlider data={data} />
                    <div className={styles.container}>
                        <div className={styles.main}>
                            <div className={styles.containerWhite}>
                                <div className={styles.content}>
                                    <div className={styles.titleContainer}>
                                        <div className={styles.title} id={"listTop"}>{translationMix(['blog', 'titleList'])}: <i>{searchText}</i></div>
                                    </div>
                                    <div className={styles.postList} >
                                        {dataList.posts.slice(0, 12).map((item, index) => {
                                            return (<Medium key={index} data={item} />)
                                        })}
                                    </div>
                                </div>
                                <div className={styles.moreSpace} />
                            </div>
                            <div className={styles.containerWhite}>
                                <div className={styles.moreSpace} />
                                <div className={styles.content}>
                                    <div className={styles.bottom}>
                                        <div className={styles.left}>
                                            <p className={styles.title}>{translationMix(['blog', 'archives'])}</p>
                                            {data.archive?.map((itme, index) => {
                                                return (<p key={index} className={styles.description}>{itme.title}</p>)
                                            })}
                                        </div>
                                        <div className={styles.right}>
                                            <p className={styles.title}>{translationMix(['blog', 'search'])}</p>
                                            <form autoComplete={"off"} id={"thisInputSearch"} action={`/${lang}/blog-search`} method={"GET"} className={styles.inputContainer}>
                                                <input className={styles.input} type={"text"} list={"thisSearchList"} name={"searchText"} placeholder={translationMix(['shared', 'searchPlaceholder'])} />
                                                <button type={"submit"} className={styles.loop} />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.moreSpace} />
                                <div className={styles.moreSpace} />
                            </div>
                        </div>
                    </div>
                </> : null}
        </>
    )
}

export default BlogList;