import React, { useEffect, useState } from "react";
import { Loader } from "../../Components/Loader/loader";
import { Link } from "react-scroll";

import Gallery from "../../Components/Sliders/Item/Galery/gallery";
import Product from "../../Components/Elements/Product/product";
import SliderItem from '../../Components/Sliders/Item/SliderItem/sliderItem';
// import SliderItemCompare from '../../Components/Sliders/Item/SliderItemCompare/sliderItemCompare';
import numberWithSpace from "../../Components/Helpers/numberWithSpace";
import useWindowSize from '../../Components/Helpers/useWindowSize';
import styles from './item.module.scss'
import { addProductCart, getOneProduct } from "../../apiCalls/productApiCalls";
import { useParams } from "react-router-dom";
import stringToUrl from "../../Components/Helpers/stringToUrl";
import BreadcrumbsContainer from "../../Components/Breadcrumbs/breadcrumbs";
import { Helmet } from "react-helmet";
import { translationMix } from "../../Components/Helpers/translationMix";

/***Not use code is for compare products and askButton */
function Item({ setCartData }) {
    const [product, setProduct] = useState(null)
    const [selectedProducts, setSelectedProducts] = useState([]);
    // const [cartInfoTime, setCartInfoTime] = useState([]);
    const { lang, id } = useParams();
    const size = useWindowSize();
    const windowsSmallSize = 950;

    const shopMenu = (data) => (<>
        <div className={styles.shopMenu}>
            <div className={styles.containerTop}>
                <div className={styles.top1}>
                    <div className={`${styles.cost} ${styles.costPadding}`}>
                        {data.discount ? <div className={styles.priceOld}>{numberWithSpace(data.price_original)}{data.currency} {translationMix(['shared', 'price', 'net'])}</div> : null}
                        <div className={`${data.discount ? styles.priceNew : styles.priceNormalNet}`}>{numberWithSpace(data.price)}{data.currency} {translationMix(['shared', 'price', 'net'])}</div>
                        <div className={styles.priceNormalGross}>{numberWithSpace(data.price_gross)}{data.currency} {translationMix(['shared', 'price', 'gross'])}</div>
                    </div>
                </div>
                <div className={styles.top2}>
                    <div className={styles.cartButton} onClick={() => { addToCart(2) }}>
                        <div className={styles.cartInfo} data-cartinfo={2}>{translationMix(['shared', 'addToCart'])}</div>
                        <div className={styles.cartNew2}>
                            <div>{translationMix(['item', 'button', 'cart'])}</div>
                            <div className={styles.cartIcon} data-carticon={2}></div>
                        </div>
                    </div>
                    <div className={styles.amount}>
                        {product.quantity <= 0 ? <div className={styles.amountNone}>{translationMix(['shared', 'amount', '2'])}</div> :
                            product.quantity < 50 ? <div className={styles.amountSmall}>{translationMix(['shared', 'amount', '1'])}</div> :
                                <div className={styles.amountBig}>{translationMix(['shared', 'amount', '0'])}</div>}
                    </div>

                </div>
            </div>
            <div className={styles.containerCenter}>{translationMix(['item', 'menu', 'delivery'])}</div>
            <div className={styles.containerBottom1}>
                <div className={styles.left}>
                    <div>{translationMix(['item', 'menu', 'costTitle'])}</div>
                    <div>{translationMix(['item', 'menu', 'costDescription'])}</div>
                </div>
                <div className={styles.right}>
                    <div className={styles.option}>
                        <p>{translationMix(['item', 'menu', 'option'])}</p>
                        <p><string>500 pln</string></p>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className={styles.containerBottom2}>
            <div className={styles.askButton}>{translation.item.button.ask}</div>
            <div className={styles.text}> {translation.item.button.askDescription}</div>
        </div> */}
    </>)

    function addToCart(id) {
        if (product) {
            const cart = document.querySelector(`[data-carticon~="${id}"]`)
            const info = document.querySelector(`[data-cartinfo~="${id}"]`)
            if (id === 2) {
                if (!cart.classList.contains(styles.animationGo2)) {
                    cart.classList.add(styles.animationGo2)
                    setTimeout(() => {
                        cart.classList.remove(styles.animationGo2)
                    }, 1000)
                }
                addProductCart(product.id, 1).then(res => {
                    setCartData(res.cart)
                    let iText = translationMix(['shared', 'addToCart'])
                    if (!res.status) {
                        iText = translationMix(['shared', 'addToCartError'])
                    }
                    info.innerText = iText
                    if (!info.classList.contains(styles.cartInfoOpacity))
                        info.classList.add(styles.cartInfoOpacity)
                    setTimeout(() => {
                        info.classList.remove(styles.cartInfoOpacity)
                    }, 3000)
                })
            } else {
                if (!cart.classList.contains(styles.animationGo)) {
                    cart.classList.add(styles.animationGo)
                    setTimeout(() => {
                        cart.classList.remove(styles.animationGo)
                    }, 1000)
                }
                addProductCart(product.id, 1).then(res => {
                    let iText = translationMix(['shared', 'addToCart'])
                    if (!res.status) {
                        iText = translationMix(['shared', 'addToCartError'])
                    } else {
                        setCartData(res.cart)
                    }

                    info.innerHTML = iText
                    if (!info.classList.contains(styles.cartInfoOpacity))
                        info.classList.add(styles.cartInfoOpacity)
                    setTimeout(() => {
                        info.classList.remove(styles.cartInfoOpacity)
                    }, 3000)
                })
            }
        }
    }

    useEffect(() => {
        let readId = id
        readId = readId.split('-')[0]

        getOneProduct(readId).then(res => {
            if (res) {
                if (res.status === 404) {
                    
                } else {
                    if (`${res.id}-${stringToUrl(res.name)}` !== id) {
                        window.location.pathname = `${lang}/product/${res.id}-${stringToUrl(res.name)}`
                    } else
                        setProduct(res)
                }
            }

        })
    }, [id, lang]);

    /**all function for compare products */
    // useEffect(() => {
    //     const arrowContainer = document.querySelector(`[data-arrow-container~="${0}"]`)
    //     const compareArrow = document.querySelector(`[data-arrow~="${0}"]`)
    //     if (compareArrow) {
    //         if (selectedProducts.length > 0) {
    //             setScrolled(false);
    //             compareArrow.classList.remove(styles.arrowRotate)
    //             arrowContainer.classList.remove(styles.containerShift)
    //             arrowContainer.classList.remove(styles.containerShiftOut)
    //         }
    //         else {
    //             setScrolled(true);
    //             if (!compareArrow.classList.contains(styles.arrowRotate))
    //                 compareArrow.classList.add(styles.arrowRotate)
    //             if (!arrowContainer.classList.contains(styles.containerShiftOut)) {
    //                 setTimeout(() => {
    //                     arrowContainer.classList.add(styles.containerShiftOut)
    //                 }, 800)
    //             }
    //         }
    //     }
    // }, [selectedProducts])

    // const addProductCompare = (event) => {
    //     let tmpLenght = selectedProducts.length;
    //     if (event.target.checked) {
    //         setSelectedProducts([...selectedProducts, event.target.id])
    //         tmpLenght++;
    //     } else {
    //         const index = selectedProducts.indexOf(event.target.id);
    //         if (index > -1) {
    //             tmpLenght--;
    //             selectedProducts.splice(index, 1);
    //             setSelectedProducts([...selectedProducts]);
    //         }
    //     }
    //     const allCompareButtons = document.querySelectorAll(`[data-comparebutton~="${0}"]`)
    //     Array.from(allCompareButtons).map((x) => { return (x.textContent = translation.shop.button.compare + "(" + (tmpLenght) + ")") })
    //     const allCheckBox = document.querySelectorAll(`[data-product-checkbox~="${event.target.id}"]`)
    //     Array.from(allCheckBox).map((x) => { return (x.checked = event.target.checked) })
    // }

    // const removeProductCompare = (event) => {
    //     let tmpLenght = selectedProducts.length;
    //     const index = selectedProducts.indexOf(event.target.id);
    //     if (index > -1) {
    //         tmpLenght--;
    //         selectedProducts.splice(index, 1);
    //         setSelectedProducts([...selectedProducts]);
    //     }

    //     const allCompareButtons = document.querySelectorAll(`[data-comparebutton~="${0}"]`)
    //     Array.from(allCompareButtons).map((x) => { return (x.textContent = translation.shop.button.compare + "(" + (tmpLenght) + ")") })
    //     const allcheckBox = document.querySelectorAll(`[data-product-checkbox~="${event.target.id}"]`)
    //     Array.from(allcheckBox).map((x) => { return (x.checked = false) })
    // }

    // const slideUpDonwHandler = (event) => {
    //     setScrolled(!scrolled)
    //     const arrowContainer = document.querySelector(`[data-arrow-container~="${0}"]`)
    //     if (event.target.classList.contains(styles.arrowRotate)) {
    //         event.target.classList.remove(styles.arrowRotate)
    //         arrowContainer.classList.remove(styles.containerShift)
    //     }
    //     else {
    //         event.target.classList.add(styles.arrowRotate)
    //         arrowContainer.classList.add(styles.containerShift)
    //     }
    // }

    return (
        <>
            <Loader data={product} />
            {product ?
                <>
                    <Helmet>
                        <title>{lang === 'pl' ? `${product.name} - Sklep internetowy Bizserver` : lang === 'en' ? `${product.name} - Bizserver online store` : `${product.name} - Online-Shop von Bizserver`}</title>
                        <meta name="description" content={`${product.description.split(/\s+/).slice(0, 10).join(" ")} - Sklep internetowy Bizserver`} />
                    </Helmet>
                    {/*<div className={`${styles.compareContainer} ${scrolled ? styles.scrolledDownShowButton : ''}`}>
                         <div className={styles.compareProducts}>
                            {selectedProducts ? selectedProducts.length > 0 ?
                                <SliderItemCompare>
                                    {selectedProducts.map((item, index) => {
                                        return (<div className={styles.compareItemContainer} key={index}>
                                            <div className={styles.itemImg} style={{ backgroundImage: `url("${data.slider[0].file_name}")` }}>
                                                <div className={styles.itemButton} id={item} onClick={(event) => { removeProductCompare(event) }}></div>
                                            </div>
                                            <div className={styles.itemText}>Product {item}</div>
                                        </div>)
                                    })}
                                </SliderItemCompare>
                                : null : null
                            }
                        </div> 
                        <div className={styles.compareButtonContainer}>
                            <div className={styles.compareButton} data-comparebutton={0}>Porównaj produkty (0)</div>
                            <div className={styles.arrowContainer} data-arrow-container={0}>
                                <div className={`${styles.compareArrow} ${styles.arrowRotate}`} data-arrow={0} onClick={(event) => { slideUpDonwHandler(event) }}></div>
                            </div>
                        </div>
                    </div>*/}
                    <div className={styles.breadcrumbContainer}>
                        <div className={styles.breadcrumb}>
                            <BreadcrumbsContainer lang={lang} />
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.containerLeft}>
                            <div className={styles.littleInfo}>
                                <div className={styles.gallery}>
                                    {product ? product.gallery ?
                                        <Gallery data={product.gallery} /> : <Gallery data={null} /> : <Gallery data={null} />}
                                </div>
                                <div className={styles.right}>
                                    <div className={styles.top}>
                                        <div className={styles.topLine}>
                                            <div className={styles.left}>
                                                {product.discount > 0 ? <div className={styles.saleButton}>{translationMix(['item', 'button', 'salary'])}</div> : null}
                                                {product ? product.manufacturer ?
                                                    <div className={styles.image} style={{ backgroundImage: `url("${product.manufacturer.image}")` }}></div>
                                                    : null : null}
                                            </div>
                                            <div className={styles.right}>
                                                {/* <label className={styles.checkbox}>{translation.item.button.compare}
                                                    <input type="checkbox" data-product-checkbox={myId} id={myId} onClick={(event) => { addProductCompare(event) }} />
                                                    <span className={styles.checkmark}></span>
                                                </label> */}
                                            </div>
                                        </div>
                                        <div className={styles.title}>{product.name}</div>
                                        <div className={styles.rating}>
                                            <div className={styles.star}>{product.rating.avg}</div>
                                            <div className={styles.text}>{translationMix(['item', 'littleInfo', 'opinion'])}</div>
                                        </div>
                                        {product.reference ?
                                            <div className={styles.description}>{translationMix(['item', 'littleInfo', 'code'])}: {product.reference}</div> : null}
                                        {product.condition ?
                                            <div className={styles.description}>{translationMix(['item', 'littleInfo', 'state'])}: {product.condition}</div>
                                            : null}
                                    </div>
                                    <div className={styles.bottom}>
                                        {product.attributes.slice(0, 4).map((item, index) => {
                                            return (<div key={index} className={styles.description}>{item.name}: {item.value}</div>)
                                        })}
                                        <div className={styles.btnParametersPlace}>
                                            <Link to={`parametersTop`} smooth={true} isDynamic={true} offset={-10} className={styles.btnParametersContainer}>
                                                <div className={styles.text}>{translationMix(['item', 'button', 'parameters'])}</div>
                                                <div className={styles.char} />
                                            </Link>
                                        </div>
                                    </div>
                                    {/* make it late
                                    <div className={styles.bottomButton}>
                                        <div className={styles.printButton}>{translation.item.button.print}</div>
                                        <div className={styles.shareButton}>{translation.item.button.share}</div>
                                        <div className={styles.saveButton}>{translation.item.button.save}</div>
                                    </div> */}
                                </div>
                            </div>
                            {size.width < windowsSmallSize ? <div>{shopMenu(product)}</div> : null}
                            <div className={styles.moreInfo}>
                                <div className={styles.title}>{translationMix(['item', 'title', 'moreInfo'])}</div>
                                {product ? <div className={styles.text} dangerouslySetInnerHTML={{ __html: product.description }} /> : null}
                            </div>
                            <div className={styles.detailedInformation}>
                                <div className={styles.title} id={"parametersTop"}>{translationMix(['item', 'title', 'detailedInfo'])}</div>
                                {product.attributes.lenght > 0 ?
                                    <div className={styles.table2}>
                                        {product.attributes.map((x, id) => {
                                            return (<div className={styles.row} key={id} >
                                                <div className={styles.element}>{x.name}</div>
                                                <div className={styles.element}>{x.value}</div>
                                            </div>)
                                        })}
                                    </div> : null}
                            </div>
                            {/* <div className={styles.compareLast}>
                                <label className={styles.checkbox}>{translation.item.button.compare}
                                    <input type="checkbox" data-product-checkbox={myId} id={myId} onClick={(event) => { addProductCompare(event) }} />
                                    <span className={styles.checkmark}></span>
                                </label>
                            </div> */}
                        </div>
                        <div className={styles.containerRight}>
                            <div className={styles.stats}>
                                <div className={`${styles.top}`}>
                                    <div className={`${styles.cost}`}>
                                        {product.discount ? <div className={styles.priceOld}>{numberWithSpace(product.price_original)}{product.currency} {translationMix(['shared', 'price', 'net'])}</div> : null}
                                        <div className={`${product.discount ? styles.priceNew : styles.priceNormalNet}`}>{numberWithSpace(product.price)}{product.currency} {translationMix(['shared', 'price', 'net'])}</div>
                                        <div className={styles.priceNormalGross}>{numberWithSpace(product.price_gross)}{product.currency} {translationMix(['shared', 'price', 'gross'])}</div>
                                    </div>

                                    <div className={styles.cartButton} onClick={() => { addToCart(1) }}>
                                        <div className={styles.cartInfo} data-cartinfo={1}></div>
                                        <div className={styles.CartNew}>
                                            <div>{translationMix(['item', 'button', 'cart'])}</div>
                                            <div className={styles.cartIcon} data-carticon={1}></div>
                                        </div>

                                    </div>
                                    <div className={styles.amount}>
                                        {product.quantity <= 0 ? <div className={styles.amountNone}>{translationMix(['shared', 'amount', '2'])}</div> :
                                            product.quantity < 50 ? <div className={styles.amountSmall}>{translationMix(['shared', 'amount', '1'])}</div> :
                                                <div className={styles.amountBig}>{translationMix(['shared', 'amount', '0'])}</div>}
                                    </div>
                                </div>
                                <div className={styles.center}>{translationMix(['item', 'menu', 'delivery'])}</div>
                                <div className={styles.bottom}>
                                    <div>{translationMix(['item', 'menu', 'costTitle'])}</div>
                                    <div>{translationMix(['item', 'menu', 'costDescription'])}</div>
                                    <div className={styles.option}>
                                        <div>{translationMix(['item', 'menu', 'option'])}</div>
                                        <div>500 pln</div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className={styles.bottom2}>
                                <div className={styles.askButton}>{translation.item.button.ask}</div>
                                <div className={styles.text}>{translation.item.button.askDescription}</div>
                            </div> */}
                        </div>
                    </div>
                    <div className={styles.containerBottom}>
                        <div className={styles.title}>{translationMix(['item', 'title', 'similar'])}</div>
                        <div className={styles.productList}>
                            {product.similar_products ?
                                <SliderItem>
                                    {product.similar_products.map((item, index) => {
                                        return (
                                            <Product data={item} key={index} myId={index} choosenOpt={selectedProducts} setChoosenOption={setSelectedProducts} preview={true} />
                                        )
                                    })}
                                </SliderItem>
                                : null}
                        </div>
                    </div>
                </> : null}
        </>
    )
}

export default Item;