import { apiUrls } from "../assets/api/apiUrls";

export async function getAllCountries() {
    const url = `${apiUrls.mainUrl}customers/get-countries`
    const options = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function getCountryById(id) {
    const url = `${apiUrls.mainUrl}customers/get-countries`
    const options = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    let endCountry = null;
    for(let i = 0; i < end.length; i++){
        if(end[i].id === id){
            endCountry = end[i]
            break;
        }
    }
    return endCountry;
}

export async function getCountryNameById(id) {
    const url = `${apiUrls.mainUrl}customers/get-countries`
    const options = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    let endCountry = null;
    for(let i = 0; i < end.length; i++){
        if(end[i].id === id){
            endCountry = end[i].name
            break;
        }
    }
    return endCountry;
}

export async function getUserData(token) {
    const url = `${apiUrls.mainUrl}customers/get-details`
    const options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function updateUserData(userData, token) {
    const url = `${apiUrls.mainUrl}customers/update-details`
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(userData)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function updateUserPassword(passwordData, token) {
    const url = `${apiUrls.mainUrl}customers/change-password`
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(passwordData)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function getUserAddress(token) {
    const url = `${apiUrls.mainUrl}customers/get-addresses`
    const options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function createUserAddress(token, addressData) {
    const url = `${apiUrls.mainUrl}customers/add-address `
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(addressData)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function updateUserAddress(token, id, addressData) {
    const url = `${apiUrls.mainUrl}customers/update-address?id=${id}`
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(addressData)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}