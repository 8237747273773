import textDE from '../../assets/translation/translationDE.json'
import textEN from '../../assets/translation/translationEN.json'
import textPL from '../../assets/translation/translationPL.json'

export function translationMix(path) {
    const lang = window.location.href.split('/')[3]

    if (lang === 'de') {
        let endText = textDE
        for (let i = 0; i < path.length; i++) {
            try { endText = endText[path[i]] } catch (error) { }
        }
        if (endText === undefined) {
            endText = textEN
            for (let i = 0; i < path.length; i++) {
                try { endText = endText[path[i]] } catch (error) { }
            }
            if (endText === undefined) {
                endText = textPL
                for (let i = 0; i < path.length; i++) {
                    try { endText = endText[path[i]] } catch (error) { }
                }
                return endText
            } else {
                return endText
            }
        } else {
            return endText
        }
    } else if (lang === 'en') {
        let endText = textEN
        for (let i = 0; i < path.length; i++) {
            try { endText = endText[path[i]] } catch (error) { }
        }
        if (endText === undefined) {
            endText = textDE
            for (let i = 0; i < path.length; i++) {
                try { endText = endText[path[i]] } catch (error) { }
            }
            if (endText === undefined) {
                endText = textPL
                for (let i = 0; i < path.length; i++) {
                    try { endText = endText[path[i]] } catch (error) { }
                }
                return endText
            } else {
                return endText
            }
        } else {
            return endText
        }
    } else if (lang === 'pl') {
        let endText = textPL
        for (let i = 0; i < path.length; i++) {
            try { endText = endText[path[i]] } catch (error) { }
        }
        if (endText === undefined) {
            endText = textDE
            for (let i = 0; i < path.length; i++) {
                try { endText = endText[path[i]] } catch (error) { }
            }
            if (endText === undefined) {
                let endText = textEN
                for (let i = 0; i < path.length; i++) {
                    try { endText = endText[path[i]] } catch (error) { }
                }
                return endText
            } else {
                return endText
            }
        } else {
            return endText
        }
    } else {
        let endText = textDE
        for (let i = 0; i < path.length; i++) {
            try { endText = endText[path[i]] } catch (error) { }
        }
        if (endText === undefined) {
            let endText = textEN
            for (let i = 0; i < path.length; i++) {
                try { endText = endText[path[i]] } catch (error) { }
            }
            if (endText === undefined) {
                endText = textPL
                for (let i = 0; i < path.length; i++) {
                    try { endText = endText[path[i]] } catch (error) { }
                }
                return endText
            } else {
                return endText
            }
        } else {
            return endText
        }
    }
}

