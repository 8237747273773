import React, { useContext, useEffect, useState } from "react";
import { apiUrls } from "../../assets/api/apiUrls";
import { Loader } from "../../Components/Loader/loader";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

import Product from "../../Components/Elements/Product/product";
import DoubleRangeSlider from "../../Components/DoubleRangeSlider/doubleRangeSlider";
import CustomSelector from "../../Components/CustomSelector/customSelector";
import CategorySlider from '../../Components/Sliders/ItemList/CategorySlider/categorySlider';
//import ShopMenuSlider from '../../Components/Sliders/ItemList/MenuSlider/menuSlider';
import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";
import useWindowSize from '../../Components/Helpers/useWindowSize';
//import numberWithSpace from "../../Components/Helpers/numberWithSpace";
import styles from './itemList.module.scss';
import LoaderAnimation from "../../Components/LoaderAnimation/loaderAnimation";
import scrollToTop from "../../Components/Helpers/scrollToTop";
import staticImage from "../../assets/img/mockups/product.jpg"
import stringToUrl from "../../Components/Helpers/stringToUrl";
import { Helmet } from "react-helmet";
import { ThemeContext } from "../../Components/Context/context";
import { getAllProduct } from "../../apiCalls/productApiCalls";
import { translationMix } from "../../Components/Helpers/translationMix";

function ItemList({ isSearch = false, myMenuId = 0, setCartData }) {
    const [isAnimation, setIsAnimation] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchQuery, setsearchQuery] = useState(null);
    const [isHorizontal, setIsHorizontal] = useState(false);
    const [products, setProducts] = useState(null)
    const [minPrice, setMinPrice] = useState(null)
    const [maxPrice, setMaxPrice] = useState(null)
    const [readMinPrice, setReadMinPrice] = useState(null)
    const [readMaxPrice, setReadMaxPrice] = useState(null)
    const [readPriceFromUrl, setReadPriceFormUrl] = useState([])
    const [readId, setReadId] = useState(myMenuId)
    const [readCurrency, setReadCurrency] = useState(null)
    const [readSearchingWord, setReadSearchingWord] = useState('')
    const [filters, setFilters] = useState(null)
    const [filtersLimit, setFiltersLimit] = useState(5)
    const [selectorOption, setSelectorOption] = useState(null)
    const [readSelectoriOption, setReadSelectoriOption] = useState(null)
    const [menuData, setMenuData] = useState(null)
    const [readedObject, setReadedObject] = useState(0)
    const [maxReadedObject, setMaxReadedObject] = useState(0)
    const [amount, setAmount] = useState(12)
    const [pageNumber, setPageNumber] = useState(1)
    const location = useLocation()
    const { lang } = useParams();
    const url = location.pathname.split('/')
    const size = useWindowSize();
    const globalContext = useContext(ThemeContext)
    const history = useHistory();

    // const selectorSubpoint = [translation.itemList.selector.s1, translation.itemList.selector.s2, translation.itemList.selector.s3, translation.itemList.selector.s4, translation.itemList.selector.s5, translation.itemList.selector.s6, translation.itemList.selector.s7, translation.itemList.selector.s8]
    const selectorSubpoint = [translationMix(['itemList', 'selector', '1']), translationMix(['itemList', 'selector', '2']), translationMix(['itemList', 'selector', '3']), translationMix(['itemList', 'selector', '4']), translationMix(['itemList', 'selector', '5']), translationMix(['itemList', 'selector', '6']), translationMix(['itemList', 'selector', '7']), translationMix(['itemList', 'selector', '8'])]

    // const bottomMenu = (<>
    //     {data ?
    //         <div className={styles.bottomMenu}>
    //             <div className={styles.title}>{translation.itemList.menu.new}</div>
    //             <div className={styles.top}>
    //                 <ShopMenuSlider>
    //                     {data.slider.map((item, index) => {
    //                         return (
    //                             <div className={styles.smallItem} key={index}>
    //                                 <div className={styles.left}>
    //                                     <div className={styles.img} style={{ backgroundImage: `url("${item.file_name}")` }}></div>
    //                                 </div>
    //                                 <div className={styles.right}>
    //                                     <div className={styles.text}>{item.title}</div>
    //                                     <div className={styles.price}>
    //                                         <div className={styles.priceNormalNet}>{numberWithSpace(2345342.23)} zł {translation.shared.price.net}</div>
    //                                         <div className={styles.priceNormalGross}>{numberWithSpace(3213542.23)} zł {translation.shared.price.gross}</div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         )
    //                     })}
    //                 </ShopMenuSlider>
    //             </div>
    //             <div className={styles.bot}>
    //                 <div className={styles.title}>{translation.itemList.menu.last}</div>
    //                 <ShopMenuSlider>
    //                     {data.slider.map((item, index) => {
    //                         return (
    //                             <div className={styles.smallItem} key={index}>
    //                                 <div className={styles.left}>
    //                                     <div className={styles.img} style={{ backgroundImage: `url("${item.file_name}")` }}></div>
    //                                 </div>
    //                                 <div className={styles.right}>
    //                                     <div className={styles.text}>{item.title}</div>
    //                                 </div>
    //                             </div>
    //                         )
    //                     })}
    //                 </ShopMenuSlider>
    //             </div>
    //         </div> : null}
    // </>)

    const slideDownUpHandler = (event, dataSet) => {
        const subMenu = document.querySelector(`[data-subpoint~="${dataSet}"]`)
        const arrow = document.querySelector(`[data-arrow~="${dataSet}"]`)
        const title = document.querySelector(`[data-title~="${dataSet}"]`)

        if (subMenu.style.height === '' || subMenu.style.height === '0px') {
            subMenu.style.height = `${subMenu.scrollHeight}px`;
            arrow.classList.remove(styles.close)
            arrow.classList.add(styles.open)
            title.classList.remove(styles.titleClose)
            title.classList.add(styles.titleOpen)
        } else {
            subMenu.style.height = '0px'
            arrow.classList.remove(styles.open)
            arrow.classList.add(styles.close)
            title.classList.remove(styles.titleOpen)
            title.classList.add(styles.titleClose)
        }
    }

    const slideCategory = (event, dataSet) => {
        const subMenu = document.querySelector(`[data-filter~="${dataSet}"]`)
        if (subMenu.scrollHeight > 20) {
            if (subMenu.style.height !== `${subMenu.scrollHeight}px`) {
                subMenu.style.maxHeight = `${subMenu.scrollHeight}px`;
                subMenu.style.height = `${subMenu.scrollHeight}px`;
            } else {
                subMenu.style.height = '45px';
            }
        }
    }

    const rotateProduct = (event) => {
        if (event.target.classList.contains(styles.vertical)) {
            event.target.classList.remove(styles.vertical)
            event.target.classList.add(styles.horizontal)
        } else {
            event.target.classList.remove(styles.horizontal)
            event.target.classList.add(styles.vertical)
        }
        setIsHorizontal(!isHorizontal)
    }

    const changeListAmount = (event, value) => {
        setAmount(value);
        setPageNumber(1);

        let parent = event.target.parentElement;
        Array.from(parent.children).map((x) => { return (x.classList.remove(styles.textActive)) })
        event.target.classList.add(styles.textActive);

        setTimeout(() => {
            let list = document.querySelector(`[filterlist~="${0}"]`);
            if (list.clientHeight > 0) {
                let tmp = 40;
                if (size.width <= 550)
                    tmp = 41;
                document.querySelector(`[data-menucontainer~="${0}"]`).style.height = `${list.clientHeight - tmp + 681 - 56}px`;
            }
        }, 0)
    }

    const changeListPage = (value, timeOut = 0) => {
        let readedPageNumber = pageNumber;
        if (value === "next") {
            readedPageNumber++
        }
        if (value === "pre") {
            readedPageNumber--
        }

        let query = searchQuery.join("&")

        let url = location.pathname;
        if (query)
            url += `?id=2&page=${readedPageNumber}&` + query
        else
            url += `?id=2&page=${readedPageNumber}`
        history.replace(url)

        setIsAnimation(true)
        const cover = document.getElementById('cover')
        cover.classList.remove(styles.hide)
        setTimeout(() => {
            cover.classList.add(styles.opacityFull)
        }, 100)
        scrollToTop(document.getElementById('listTop').offsetTop - 10, 1000)
        setTimeout(() => {
            let char = '&'
            if (!query)
                char = '';
            getAllProduct(`${apiUrls.mainUrl}${apiUrls.categoryProducts}${readId}&limit=${amount}&page=${readedPageNumber}${char}${query}`)
                .then((json) => {
                    setReadedObject(json.total_count)
                    setProducts(json.products)
                    cover.classList.remove(styles.opacityFull)
                    setTimeout(() => {
                        setIsAnimation(false)
                    }, 500)
                })
        }, 500)


        setTimeout(() => {
            setPageNumber(readedPageNumber);
            setTimeout(() => {
                let list = document.querySelector(`[filterlist~="${0}"]`);
                if (list.clientHeight > 0) {
                    let tmp = 40;
                    if (size.width <= 550)
                        tmp = 41;
                    document.querySelector(`[data-menucontainer~="${0}"]`).style.height = `${list.clientHeight - tmp + 681 - 56}px`;
                }
            }, timeOut + 1)
        }, timeOut)


    }

    const roundNumber = (value, amount) => {
        if (value) {
            let tmp = Math.round(value / amount);
            if (tmp < value / amount) {
                return tmp + 1;
            } else return tmp;
        }
    }

    const slideLeftRightHandler = (event, dataSet) => {
        const subMenu = document.querySelector(`[data-filtercontainer~="${0}"]`)

        if (subMenu.classList.contains(styles.menuOpen)) {
            subMenu.classList.remove(styles.menuOpen);
            subMenu.classList.add(styles.menuClose);
            subMenu.classList.add(styles.filterTransition);
            event.target.classList.remove(styles.buttonOpen)
            event.target.classList.add(styles.buttonClose)
            event.target.classList.add(styles.filterTransition);
            subMenu.addEventListener('transitionend', () => {
                subMenu.classList.remove(styles.filterTransition);
                event.target.classList.remove(styles.filterTransition);
            }, { once: true });
        }
        else {
            subMenu.classList.remove(styles.menuClose);
            subMenu.classList.add(styles.menuOpen);
            subMenu.classList.add(styles.filterTransition);
            event.target.classList.remove(styles.buttonClose)
            event.target.classList.add(styles.buttonOpen)
            event.target.classList.add(styles.filterTransition);
            subMenu.addEventListener('transitionend', () => {
                subMenu.classList.remove(styles.filterTransition);
                event.target.classList.remove(styles.filterTransition);
            }, { once: true });
        }
    }

    requestAnimationFrame(() => {
        let list = document.querySelector(`[filterlist~="${0}"]`);
        if (list?.clientHeight > 0) {
            let tmp = 40;
            if (size.width <= 550)
                tmp = 41;
            document.querySelector(`[data-menucontainer~="${0}"]`).style.height = `${list.clientHeight - tmp + 681 - 56}px`;
        }
    });

    function removeFromArray(array, item) {
        const index = array.indexOf(item);
        if (index > -1) { // only splice array when item is found
            array.splice(index, 1); // 2nd parameter means remove one item only
            setsearchQuery([...array])
        }
        return array
    }

    function filterProducts(event, type, option, id = -1) {
        if (type === "quantity") {
            if (option === "available") {
                if (event.target.checked)
                    setsearchQuery([...searchQuery, "quality=1"])
                else
                    removeFromArray(searchQuery, "quality=1")
                // query = addToQuery(query, "quality=1")
            } else if (option === "not available") {
                if (event.target.checked)
                    setsearchQuery([...searchQuery, "quality=0"])
                else
                    removeFromArray(searchQuery, "quality=0")
            }
        } else if (type === "condition") {
            if (option === "New") {
                if (event.target.checked)
                    setsearchQuery([...searchQuery, "condition=new"])
                else
                    removeFromArray(searchQuery, "condition=new")
            } else if (option === "Used") {
                if (event.target.checked)
                    setsearchQuery([...searchQuery, "condition=used"])
                else
                    removeFromArray(searchQuery, "condition=used")
            } else if (option === "Refurbished") {
                if (event.target.checked)
                    setsearchQuery([...searchQuery, "condition=refurbished"])
                else
                    removeFromArray(searchQuery, "condition=refurbished")
            }
        } else if (type === "manufacturer") {
            if (event.target.checked)
                setsearchQuery([...searchQuery, `manufacturer=${id}`])
            else
                removeFromArray(searchQuery, `manufacturer=${id}`)
        } else if (type === "attributeValue") {
            if (event.target.checked)
                setsearchQuery([...searchQuery, `attributeValue=${id}`])
            else
                removeFromArray(searchQuery, `attributeValue=${id}`)
        }
    }

    function checkboxChecked(type, option, id = -1) {
        if (type === "quantity") {
            if (option === "available") {
                if (searchQuery.includes("quality=1"))
                    return true;
            } else if (option === "not available") {
                if (searchQuery.includes("quality=0"))
                    return true;
            } else
                return false;
        } else if (type === "condition") {
            if (option === "New") {
                if (searchQuery.includes("condition=new"))
                    return true;
            } else if (option === "Used") {
                if (searchQuery.includes("condition=used"))
                    return true;
            } else if (option === "Refurbished") {
                if (searchQuery.includes("condition=refurbished"))
                    return true;
            } else
                return false;
        } else if (type === "manufacturer") {
            if (searchQuery.includes(`manufacturer=${id}`)) {
                return true;
            } else
                return false;
        } else if (type === "attributeValue") {
            if (searchQuery.includes(`attributeValue=${id}`))
                return true;
            else
                return false;
        } else {
            return false;
        }
        return false;
    }

    function changeFilterLimit() {
        if (filtersLimit === 5) {
            setFiltersLimit(-1)
        } else {
            setFiltersLimit(5)
        }
    }

    //read from url parms to filters
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let readId = myMenuId;
        if (isSearch) {
            readId = 0;
        }
        setReadId(readId);
        const readWord = urlParams.get('searchQuery');
        if (readWord)
            setReadSearchingWord(readWord)
        if (!searchQuery) {
            let allQueryParams = []
            let readBy = ''
            let readWay = ''
            let readMinPrice = -1;
            let readMaxPrice = -1;
            urlParams.forEach((x, y) => {
                if (y !== 'id' && y !== 'page') {
                    allQueryParams.push(`${y}=${x.replace("-", "_")}`)
                    if (y === 'price') {
                        readMinPrice = x.split("-")[0]
                        readMaxPrice = x.split("-")[1]
                        setReadPriceFormUrl([Number(readMinPrice), Number(readMaxPrice)])
                        // if (!minPrice)
                        //     setMinPrice(x.split("-")[0])
                        // if (!maxPrice)
                        //     setMaxPrice(x.split("-")[1])
                    }
                    if (y === 'orderBy') {
                        readBy = x
                    }
                    if (y === 'orderWay') {
                        readWay = x
                    }
                }
            })
            if (readBy === 'price') {
                if (readWay === 'asc')
                    setReadSelectoriOption(1)
                else if (readWay === 'desc')
                    setReadSelectoriOption(2)
            } else if (readBy === 'quantity') {
                if (readWay === 'desc')
                    setReadSelectoriOption(3)
                else if (readWay === 'asc')
                    setReadSelectoriOption(4)
            } else if (readBy === 'rating') {
                if (readWay === 'asc')
                    setReadSelectoriOption(5)
                else if (readWay === 'desc')
                    setReadSelectoriOption(6)
            } else if (readBy === 'name') {
                if (readWay === 'asc')
                    setReadSelectoriOption(7)
                else if (readWay === 'desc')
                    setReadSelectoriOption(8)
            }
            setsearchQuery([...allQueryParams])
        }
    }, [searchQuery, isSearch, myMenuId, minPrice, maxPrice]);

    useEffect(() => {

        if (globalContext.menuData) {
            let readId = myMenuId;
            if (isSearch) {
                readId = 0;
            }
            let find = false;
            for (let i = 0; i < globalContext.menuData.length; i++) {
                if (globalContext.menuData[i].id === Number(readId)) {
                    setMenuData(globalContext.menuData[i])
                    find = true;
                    break;
                } else if (globalContext.menuData[i].children.length > 0) {
                    for (let j = 0; j < globalContext.menuData[i].children.length; j++) {
                        if (globalContext.menuData[i].children[j].id === Number(readId)) {
                            setMenuData(globalContext.menuData[i].children[j])
                            find = true;
                            break;
                        } else if (globalContext.menuData[i].children[j].children.length > 0) {
                            for (let k = 0; k < globalContext.menuData[i].children[j].children.length; k++) {
                                if (globalContext.menuData[i].children[j].children[k].id === Number(readId)) {
                                    setMenuData(globalContext.menuData[i].children[j].children[k])
                                    find = true;
                                    break;
                                }
                            } if (find)
                                break;
                        }
                        if (find)
                            break;
                    }
                }
                else if (i === globalContext.menuData.length - 1) {
                    if (!find && !isSearch) {
                        window.location.replace(`noPage`);
                    }
                }
            }
        }
    }, [url, isSearch, myMenuId, globalContext.menuData]);

    useEffect(() => {
        if (products) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let readPageNumber = urlParams.get('page');
            if (isNaN(readPageNumber) || !readPageNumber)
                readPageNumber = 1;
            else if (readPageNumber > roundNumber(readedObject, 12) || readPageNumber <= 0) {
                window.location.replace(`noPage`);
            }
            setPageNumber(Number(readPageNumber));//need number because will write string and 'if' not woriking
        }
    }, [products, readedObject]);

    useEffect(() => {
        if (minPrice >= 0 && maxPrice) {
            let findIndex = -1
            for (let i = 0; i < searchQuery.length; i++) {
                if (searchQuery[i].startsWith('price')) {
                    findIndex = i;
                    break;
                }
            }

            if (findIndex > -1) {
                let prices = searchQuery[findIndex].substring(6)
                let priceArray = prices.split('_')
                if (minPrice !== Number(priceArray[0]) || maxPrice !== Number(priceArray[1])) {
                    let tmpArray = searchQuery.filter(function (item) {
                        return item.indexOf("price") !== 0;
                    });
                    const index = searchQuery.indexOf(`price`);
                    if (index > -1) {
                        searchQuery.splice(index, 1);
                        setsearchQuery([...searchQuery, `price=${minPrice}_${maxPrice}`])
                    } else {
                        setsearchQuery([...tmpArray, `price=${minPrice}_${maxPrice}`])
                    }
                }
            } else {
                let tmpArray = searchQuery.filter(function (item) {
                    return item.indexOf("price") !== 0;
                });
                const index = searchQuery.indexOf(`price`);
                if (index > -1) {
                    searchQuery.splice(index, 1);
                    setsearchQuery([...searchQuery, `price=${minPrice}_${maxPrice}`])
                } else {
                    setsearchQuery([...tmpArray, `price=${minPrice}_${maxPrice}`])
                }
            }
        }
    }, [minPrice, maxPrice, searchQuery])

    useEffect(() => {
        if (selectorOption) {
            let findIndex = -1
            for (let i = 0; i < searchQuery.length; i++) {
                if (searchQuery[i].startsWith('orderBy')) {
                    findIndex = i;
                    break;
                }
            }

            let tmpArray = searchQuery.filter(function (item) {
                return item.indexOf("orderBy") !== 0;
            });
            tmpArray = tmpArray.filter(function (item) {
                return item.indexOf("orderWay") !== 0;
            });
            if (selectorOption === 1) {
                if (searchQuery[findIndex] !== `orderBy=price&orderWay=asc`)
                    setsearchQuery([...tmpArray, `orderBy=price&orderWay=asc`])
            } else if (selectorOption === 2) {
                if (searchQuery[findIndex] !== `orderBy=price&orderWay=desc`)
                    setsearchQuery([...tmpArray, `orderBy=price&orderWay=desc`])
            } else if (selectorOption === 3) {
                if (searchQuery[findIndex] !== `orderBy=quantity&orderWay=desc`)
                    setsearchQuery([...tmpArray, `orderBy=quantity&orderWay=desc`])
            } else if (selectorOption === 4) {
                if (searchQuery[findIndex] !== `orderBy=quantity&orderWay=asc`)
                    setsearchQuery([...tmpArray, `orderBy=quantity&orderWay=asc`])
            } else if (selectorOption === 5) {
                if (searchQuery[findIndex] !== `orderBy=rating&orderWay=asc`)
                    setsearchQuery([...tmpArray, `orderBy=rating&orderWay=asc`])
            } else if (selectorOption === 6) {
                if (searchQuery[findIndex] !== `orderBy=rating&orderWay=desc`)
                    setsearchQuery([...tmpArray, `orderBy=rating&orderWay=desc`])
            } else if (selectorOption === 7) {
                if (searchQuery[findIndex] !== `orderBy=name&orderWay=asc`)
                    setsearchQuery([...tmpArray, `orderBy=name&orderWay=asc`])
            } else if (selectorOption === 8) {
                if (searchQuery[findIndex] !== `orderBy=name&orderWay=desc`)
                    setsearchQuery([...tmpArray, `orderBy=name&orderWay=desc`])
            }
        }
    }, [selectorOption, searchQuery])

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let readPageNumber = urlParams.get('page');
        let tmpId = -1;
        if (!readId) {
            // const queryString = window.location.search;
            // const urlParams = new URLSearchParams(queryString);
            tmpId = myMenuId;
        }
        let query = '';
        if (searchQuery) {
            // let tmpArray = searchQuery.filter(function (item) {
            //     return item.indexOf("orderBy") !== 0;
            // });
            //let queryUrl = tmpArray.join("&")
            let url = location.pathname;
            query = searchQuery.join("&")

            if (readPageNumber)
                url += "?page=" + readPageNumber + "&" + query
            else if (query)
                url += "?" + query.replace("_", "-")

            history.replace(url)
        }

        setIsAnimation(true)
        const cover = document.getElementById('cover')
        cover?.classList.remove(styles.hide)
        setTimeout(() => {
            cover?.classList.add(styles.opacityFull)
        }, 1)
        const top = document.getElementById('listTop');
        if (top && searchQuery?.length > 0)
            scrollToTop(document.getElementById('listTop').offsetTop - 10, 1000)
        setTimeout(() => {
            if (!searchQuery) {

            } else {
                let char = '&'
                if (!query)
                    char = '';
                getAllProduct(`${apiUrls.mainUrl}${apiUrls.categoryProducts}${readId ? readId === 0 ? tmpId : readId : tmpId}&limit=${amount}&page=${readPageNumber ? readPageNumber : 1}${char}${query}`)
                    .then((json) => {
                        setMaxReadedObject(json.total_count)
                        setReadedObject(json.current_count)
                        setProducts(json.products)
                        if (isSearch)
                            setMenuData(globalContext.menuData[0])
                        setFilters(json.left_filters)
                        setReadCurrency(json.currency)
                        // if (isSearch || globalContextRef.current.itemListFirstLoad) {
                        if (isSearch) {
                            json.left_filters.forEach((item, index) => {
                                if (item.type === "price") {
                                    setReadMinPrice(item.min)
                                    setReadMaxPrice(item.max)
                                }
                            })
                        } else {
                            json.left_filters.forEach((item, index) => {
                                if (item.type === "price") {
                                    setReadMinPrice(item.absolute_min)
                                    setReadMaxPrice(item.absolute_max)
                                }
                            })
                        }
                        if (cover)
                            cover.classList.remove(styles.opacityFull)
                        setTimeout(() => {
                            setIsAnimation(false)
                        }, 500)
                    })
            }
        }, 500)


    }, [searchQuery, amount, readId, isSearch, location.pathname, myMenuId, history, globalContext.menuData])

    return (
        <>
            <Helmet>
                <title>{lang === 'pl' ? `${menuData ? menuData.name : 'Bizserver'} - Sklep internetowy Bizserver` : lang === 'en' ? `${menuData ? menuData.name : 'Bizserver'} - Bizserver online store` : `${menuData ? menuData.name : 'Bizserver'} - Online-Shop von Bizserver`}</title>
                <meta name="description" content={`${menuData ? menuData.description.split(/\s+/).slice(0, 10).join(" ") : 'Bizserver ⬅️ Serwery i komponenty dla firm - Sklep internetowy Bizserver'}`} />
            </Helmet>
            <Loader data={products} scroll={false} />
            <div className={styles.container}>
                <div className={styles.containerLeft}>
                    <div className={`${styles.menu} ${styles.menuBorder}`}>
                        <div className={styles.menuTitle}>{menuData ? menuData.name : null}</div>
                        {menuData ? menuData.children ? <>
                            {menuData.children.map((item, index) => {
                                return (
                                    <div className={styles.point} key={index}>
                                        {item.children && item.children.length ?
                                            <div className={styles.title} data-title={index}
                                                onClick={(event) => {
                                                    if (item.children && item.children.length) {
                                                        slideDownUpHandler(event, index)
                                                    } else {
                                                        //there is no else - useless code
                                                        // window.location.href = `${window.location.href}/${stringToUrl(item.name)}`
                                                        // <Link to={`/${stringToUrl(item.name)}`}></Link>
                                                    }
                                                }}
                                            >{item.name}
                                                {item.children ? item.children.length ?
                                                    <div className={styles.char}>
                                                        <div className={styles.close} data-arrow={index} />
                                                    </div> : null : null}
                                            </div> :
                                            <Link to={`${window.location.pathname}/${stringToUrl(item.name_main)}`}><div className={styles.myLink}>{item.name}</div></Link>}
                                        {item.children ? item.children.length ?
                                            <>
                                                <div className={styles.subPoints} data-subpoint={index}>
                                                    {item.children.map((item2, index) => {
                                                        return (
                                                            <Link to={`${window.location.pathname}/${stringToUrl(item.name_main)}/${stringToUrl(item2.name_main)}`} className={styles.subPoint} key={index}>{item2.name}</Link>
                                                        )
                                                    })}
                                                </div> </> : null : null}
                                    </div>)
                            })}
                        </> : null : null}
                    </div>
                    <div className={styles.filter}>
                        <div className={styles.fTitle}>{translationMix(['itemList', 'menu', 'filters'])}</div>
                        <div className={styles.filterContainer}>
                            {filters ? filters.map((item, index) => {
                                if (filtersLimit <= 0 || index <= filtersLimit) {
                                    if (item.type === "price") {
                                        return (
                                            <div className={styles.price} key={index}>
                                                <div className={styles.pTitle}>{translationMix(['itemList', 'menu', 'priceTitel'])}</div>
                                                <div className={styles.slider}>
                                                    {readMinPrice !== null && readMaxPrice !== null ?
                                                        <DoubleRangeSlider
                                                            minValue={readMinPrice}
                                                            maxValue={readMaxPrice}
                                                            id={0}
                                                            setMinPrice={setMinPrice}
                                                            setMaxPrice={setMaxPrice}
                                                            selectedPrices={readPriceFromUrl}
                                                            setSelectedPrices={setReadPriceFormUrl}
                                                            currencyChar={readCurrency}
                                                        />
                                                        : null}
                                                </div>
                                            </div>)
                                    } else {
                                        return (
                                            <div className={styles.category} key={index}>
                                                <div className={styles.title} onClick={(event) => {
                                                    if (item.values.length > 0) {
                                                        slideCategory(event, index)
                                                    }
                                                }}>{item.name}</div>
                                                <div className={styles.categoryContainer} data-filter={index}>
                                                    {item.values.map((item2, index2) => {
                                                        return (
                                                            <div className={styles.categoryElement} key={index2}>
                                                                <label className={styles.checkbox}>{item2.name} ({item2.nb})
                                                                    <input type="checkbox" onChange={(event) => event.preventDefault()}
                                                                        value={checkboxChecked(item.type, item2.type, item2.id)}
                                                                        checked={checkboxChecked(item.type, item2.type, item2.id)}
                                                                        onClick={(event) => { if (item2.nb > 0) if (item2.id) filterProducts(event, item.type, item2.type, item2.id); else filterProducts(event, item.type, item2.type) }} />
                                                                    {item2.nb > 0 ? <span className={styles.checkmark}></span> : null}
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>)
                                    }
                                } else
                                    return null
                            }) : null}
                            <div className={styles.filtersButton}>
                                <div className={styles.menuButton} onClick={() => { changeFilterLimit() }}>{translationMix(['itemList', 'button', 'filters'])}</div>
                            </div>

                        </div>
                    </div>
                    {/* <div className={styles.new}>
                            <div className={styles.title}>{translation.itemList.menu.new}</div>
                            {data ? <>
                                {data.slider.map((item, index) => {
                                    return (
                                        <div className={styles.smallItem} key={index}>
                                            <div className={styles.left}>
                                                <div className={styles.img} style={{ backgroundImage: `url("${item.file_name}")` }}></div>
                                            </div>
                                            <div className={styles.right}>
                                                <div className={styles.text}>{item.title}</div>
                                                <div className={styles.price}>
                                                    <div className={styles.priceNormalNet}>{numberWithSpace(2482342.23)} zł {translation.shared.price.net}</div>
                                                    <div className={styles.priceNormalGross}>{numberWithSpace(3978542.23)} zł {translation.shared.price.gross}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </> : null}
                            <div className={styles.newButton}>
                                <div className={styles.menuButton}>{translation.itemList.button.new}</div>
                            </div>
                        </div>
                        <div className={styles.last}>
                            <div className={styles.title}>{translation.itemList.menu.last}</div>
                            {data ? <>
                                {data.slider.map((item, index) => {
                                    return (
                                        <div className={styles.smallItem} key={index}>
                                            <div className={styles.left}>
                                                <div className={styles.img} style={{ backgroundImage: `url("${item.file_name}")` }}></div>
                                            </div>
                                            <div className={styles.right}>
                                                <div className={styles.text}>{item.title}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </> : null}
                        </div> */}
                </div>
                <div className={styles.containerRight}>
                    <div className={styles.breadcrumb}>
                        <Breadcrumbs lang={lang} />
                    </div>
                    <div className={styles.menuContainer} data-menucontainer={0}>
                        <div className={`${styles.filterMenuContainer} ${styles.menuClose}`} data-filtercontainer={0}>
                            <div className={styles.filterMenu} >
                                <div className={`${styles.menu} ${styles.menuMobile}`}>
                                    <div className={styles.menuTitle}>{menuData ? menuData.name : null}</div>
                                    {menuData ? menuData.children ? <>
                                        {menuData.children.map((item, index) => {
                                            return (
                                                <div className={styles.point} key={index}>
                                                    {item.children && item.children.length ?
                                                        <div className={styles.title} data-title={(index + 100)}
                                                            onClick={(event) => {
                                                                if (item.children && item.children.length) {
                                                                    slideDownUpHandler(event, (index + 100))
                                                                }
                                                            }}
                                                        >{item.name}
                                                            {item.children ? item.children.length ?
                                                                <div className={styles.char}>
                                                                    <div className={styles.close} data-arrow={(index + 100)} />
                                                                </div> : null : null}
                                                        </div> :
                                                        <Link to={`${window.location.pathname}/${stringToUrl(item.name_main)}`}><div className={styles.myLink}>{item.name}</div></Link>}
                                                    {item.children ? item.children.length ?
                                                        <>
                                                            <div className={styles.subPoints} data-subpoint={(index + 100)}>
                                                                {item.children.map((item2, index) => {
                                                                    return (
                                                                        <Link to={`${window.location.pathname}/${stringToUrl(item.name_main)}/${stringToUrl(item2.name_main)}`} className={styles.subPoint} key={index}>{item2.name}</Link>
                                                                    )
                                                                })}
                                                            </div> </> : null : null}
                                                </div>)
                                        })}
                                    </> : null : null}
                                </div>
                                <div className={styles.filter}>
                                    <div className={styles.fTitle}>{translationMix(['itemList', 'menu', 'filters'])}</div>
                                    <div className={styles.filterContainer}>
                                        {filters ? filters.map((item, index) => {
                                            if (item.type === "price") {
                                                return (
                                                    <div className={styles.price} key={index}>
                                                        <div className={styles.pTitle}>{translationMix(['itemList', 'menu', 'priceTitel'])}</div>
                                                        <div className={styles.slider}>
                                                            {readMinPrice !== null && readMaxPrice !== null ?
                                                                <DoubleRangeSlider
                                                                    minValue={readMinPrice}
                                                                    maxValue={readMaxPrice}
                                                                    id={1}
                                                                    setMinPrice={setMinPrice}
                                                                    setMaxPrice={setMaxPrice}
                                                                    selectedPrices={readPriceFromUrl}
                                                                    setSelectedPrices={setReadPriceFormUrl}
                                                                    currencyChar={readCurrency}
                                                                />
                                                                : null}
                                                        </div>
                                                    </div>)
                                            } else {
                                                return (
                                                    <div className={styles.category} key={index}>
                                                        <div className={styles.title} onClick={(event) => {
                                                            if (item.values.length > 0) {
                                                                slideCategory(event, index + filters.length)
                                                            }
                                                        }}>{item.name}</div>
                                                        <div className={styles.categoryContainer} data-filter={index + filters.length}>
                                                            {item.values.map((item2, index2) => {
                                                                return (
                                                                    <div className={styles.categoryElement} key={index2}>
                                                                        <label className={styles.checkbox}>{item2.name} ({item2.nb})
                                                                            <input type="checkbox" onChange={(event) => event.preventDefault()}
                                                                                value={checkboxChecked(item.type, item2.type, item2.id)}
                                                                                checked={checkboxChecked(item.type, item2.type, item2.id)}
                                                                                onClick={(event) => { if (item2.nb > 0) if (item2.id) filterProducts(event, item.type, item2.type, item2.id); else filterProducts(event, item.type, item2.type) }} />
                                                                            {item2.nb > 0 ? <span className={styles.checkmark}></span> : null}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>)
                                            }
                                        }) : null}
                                        {/* <div className={styles.filtersButton}>
                                                <div className={styles.menuButton}>{translation.itemList.button.filters}</div>
                                            </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.filterButton} `} onClick={(event) => { slideLeftRightHandler(event, 0) }} />
                        </div>
                    </div>
                    <div className={styles.containerTop}>
                        <div className={styles.info}>
                            <div className={styles.description}>
                                <div className={styles.title}>{isSearch ? `Wyszukania dla słowa: ${readSearchingWord}` : menuData ? menuData.name : null} ({maxReadedObject ? maxReadedObject : "0"})</div>
                                {size.width <= 800 ? <div className={styles.imgContainer}>
                                    {menuData ?
                                        <div className={styles.img} style={{ backgroundImage: `url("${menuData.image_name}")` }}></div>
                                        : null}
                                </div> : null}
                                {menuData ?
                                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: menuData.description }} /> : null}
                            </div>
                            {size.width > 800 ? <div className={styles.imgContainer}>
                                {menuData ?
                                    <div className={styles.img} style={{ backgroundImage: `url("${menuData.image_name}")` }}></div>
                                    : null}
                            </div> : null}
                        </div>
                        <div className={styles.category}>
                            {menuData ? menuData.children ? menuData.children.length > 0 ?
                                <div className={styles.title}>{translationMix(['itemList', 'title', 'subcategories'])}:</div> : null : null : null}
                            <div className={styles.list}>
                                {menuData ? menuData.children ?
                                    <>
                                        <CategorySlider>
                                            {menuData.children.map((item, index) => {
                                                return (
                                                    <Link to={`${window.location.pathname}/${stringToUrl(item.name_main)}`} className={styles.subcategory} key={index}>
                                                        <div className={styles.img} style={{ backgroundImage: `url("${staticImage}")` }} ></div>
                                                        <div className={styles.title}>{item.name}</div>
                                                    </Link>
                                                )
                                            })}
                                        </CategorySlider>
                                    </> : null : null}
                            </div>
                        </div>
                    </div>
                    <div className={styles.containerCenter}>
                        <div className={styles.filterTop} id={`listTop`}>
                            <div className={styles.left}>
                                {readedObject > 0 ? <CustomSelector data={selectorSubpoint} setSelectorOption={setSelectorOption} selectedId={readSelectoriOption} /> : null}
                                {readedObject > 0 ? <div className={`${styles.sortButton} ${styles.vertical}`} onClick={(event) => { rotateProduct(event) }}></div> : null}
                                <div className={styles.count}>
                                    {readedObject > 0 ? <div className={styles.text2}>{translationMix(['itemList', 'button', 'show'])}</div> : null}
                                    <div className={styles.number}>
                                        {readedObject > 0 ? <Link to={`${location.pathname}?id=${menuData ? menuData.id : isSearch ? 0 : null}&page=1`} className={`${styles.text} ${styles.textActive}`} show={0} onClick={(event) => { changeListAmount(event, 12) }}>12</Link> : null}
                                        {readedObject >= 24 ? <Link to={`${location.pathname}?id=${menuData ? menuData.id : isSearch ? 0 : null}&page=1`} className={styles.text} show={1} onClick={(event) => { changeListAmount(event, 24) }}>24</Link> : null}
                                        {readedObject >= 60 ? <Link to={`${location.pathname}?id=${menuData ? menuData.id : isSearch ? 0 : null}&page=1`} className={styles.text} show={2} onClick={(event) => { changeListAmount(event, 60) }}>60</Link> : null}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.right}>
                                {/* <div className={styles.compareButton} data-comparebutton={0}>{translation.itemList.button.compare} (0)</div> */}
                            </div>
                        </div>
                        <div className={styles.filterCenter}>

                            <div className={styles.top}>
                                <div className={styles.info}>
                                    <span position={0}>{readedObject > 0 ? ((pageNumber - 1) * amount) + 1 : 0}</span>
                                    <span>-</span>
                                    <span>{(pageNumber * amount) <= readedObject ? (pageNumber * amount) : readedObject}</span>
                                    <span>{translationMix(['itemList', 'button', 'of'])}</span>
                                    <span>{readedObject ? readedObject : "0"}</span>
                                    <span>{translationMix(['itemList', 'button', 'position'])}</span>
                                </div>
                                {readedObject > 0 ?
                                    <div className={styles.navigation}>
                                        {/* 
                                            to={`${location.pathname}?id=${menuData.id}&page=${pageNumber - 1}`} 
                                            to={`${location.pathname}?id=${menuData.id}&page=${pageNumber + 1}`}
                                            */}
                                        <div className={`${styles.text} ${styles.button} ${pageNumber !== 1 ? styles.textActive : ''}`} onClick={() => { changeListPage("pre") }} navibutton={0} >&lt; {translationMix(['itemList', 'button', 'previous'])}</div>
                                        <div className={`${styles.text} ${styles.textActive} ${styles.textUnderline}`} position={2}>{pageNumber}</div>
                                        <div className={styles.text}>{translationMix(['itemList', 'button', 'of'])}</div>
                                        <div className={styles.text} position={3}>{roundNumber(readedObject, amount)}</div>
                                        <div className={`${styles.text} ${styles.button} ${pageNumber !== roundNumber(readedObject, amount) ? styles.textActive : ''}`} onClick={() => { changeListPage("next") }} navibutton={1}>{translationMix(['itemList', 'button', 'next'])} &gt;</div>
                                    </div> : null}
                                <div className={styles.empty}></div>
                            </div>
                            <div className={styles.filterList} filterlist={0}>
                                {products ?
                                    <>
                                        {/* {products.slice((pageNumber - 1) * amount, (pageNumber) * amount).map((item, index) => { */}
                                        {products.map((item, index) => {
                                            return (
                                                <Product data={item} key={index} myId={item.id} choosenOpt={selectedProducts} setChoosenOption={setSelectedProducts} horizontal={isHorizontal} setCartData={setCartData} />
                                            )
                                        })}
                                    </>
                                    : null}
                                <div className={`${styles.cover} ${readedObject - (pageNumber - 1) * amount < 5 ? readedObject - (pageNumber - 1) * amount > 0 ? styles.noPadding : styles.noPadding : styles.noPadding}`} id={'cover'} >{isAnimation ?
                                    size.width < 500 ? <LoaderAnimation width={85} height={85} /> : <LoaderAnimation /> : null} </div>
                            </div>
                        </div>
                        <div className={styles.filterBot}>
                            <div className={styles.left}></div>
                            <div className={styles.center}>
                                {readedObject > 0 ?
                                    <div className={styles.navigation}>
                                        <div className={`${styles.text} ${styles.button} ${pageNumber !== 1 ? styles.textActive : ''}`} onClick={() => { changeListPage("pre", 700) }} navibutton={2}>&lt; {translationMix(['itemList', 'button', 'previous'])}</div>
                                        <div className={`${styles.text} ${styles.textActive} ${styles.textUnderline}`} position={4}>{pageNumber}</div>
                                        <div className={styles.text}>{translationMix(['itemList', 'button', 'of'])}</div>
                                        <div className={styles.text} position={5}>{roundNumber(readedObject, amount)}</div>
                                        <div className={`${styles.text} ${styles.button} ${pageNumber !== roundNumber(readedObject, amount) ? styles.textActive : ''}`} onClick={() => { changeListPage("next", 700) }} navibutton={3}>{translationMix(['itemList', 'button', 'next'])} &gt;</div>
                                    </div> : null}
                            </div>
                            <div className={styles.right}>
                                {/* <div className={styles.compareButton} data-comparebutton={0} >{translation.itemList.button.compare} (0)</div> */}
                            </div>
                        </div>
                    </div>
                    {/* {data ? <> {size.width <= windowsSmallSize ? <div>{bottomMenu}</div> : null}</> : null} */}
                    <div className={styles.containerBottom}>
                        {menuData?.description_bottom ? <p className={styles.text} dangerouslySetInnerHTML={{ __html: menuData.description_bottom }} /> : null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ItemList;