import React from "react";

import styles from './footer.module.scss'
import scrollToTop from "../../Components/Helpers/scrollToTop";
import { Link, useParams } from "react-router-dom";
import Coookie from "../../Components/Elements/Cookie/cookie";
import { translationMix } from "../../Components/Helpers/translationMix";

function Footer() {
    const { lang } = useParams();
    return (
        <div className={styles.wrapper}>
            <Coookie />
            <div className={styles.top}>
                <div className={styles.itemsWrapper}>
                    <div className={styles.itemWrapper}>
                        <div className={styles.title}>{translationMix(['footer', 'top', 'info', 'title'])}</div>
                        {/* <div className={styles.pointLink}>{translation.footer.top.info["1"]}</div>
                        <div className={styles.pointLink}>{translation.footer.top.info["2"]}</div>
                        <div className={styles.pointLink}>{translation.footer.top.info["3"]}</div>
                        <div className={styles.pointLink}>{translation.footer.top.info["4"]}</div> */}
                        <Link to={`/${lang}/contact`} className={styles.pointLink}>{translationMix(['footer', 'top', 'info', '5'])}</Link>
                        <Link to={`/${lang}/shop-rules`} className={styles.pointLink}>{translationMix(['footer', 'top', 'info', '6'])}</Link>
                        <Link to={`/${lang}/privacy-policy`} className={styles.pointLink}>{translationMix(['footer', 'top', 'info', '7'])}</Link>
                        <Link to={`/${lang}/payment-methods`} className={styles.pointLink}>{translationMix(['footer', 'top', 'info', '8'])}</Link>
                        <Link to={`/${lang}/about-us`} className={styles.pointLink}>{translationMix(['footer', 'top', 'info', '9'])}</Link>
                    </div>
                    <div className={styles.itemWrapper}>
                        <div className={styles.title}>{translationMix(['footer', 'top', 'myAccount', 'title'])}</div>
                        {/* <div className={styles.pointLink}>{translation.footer.top.myAccount["1"]}</div> */}
                        {/* <div className={styles.pointLink}>{translation.footer.top.myAccount["2"]}</div> */}
                        {/* <div className={styles.poipointLinknt}>{translation.footer.top.myAccount["3"]}</div> */}
                        <Link to={`/${lang}/account-address`} className={styles.pointLink}>{translationMix(['footer', 'top', 'myAccount', '4'])}</Link>
                        <Link to={`/${lang}/account-user`} className={styles.pointLink}>{translationMix(['footer', 'top', 'myAccount', '5'])}</Link>
                        {/* <div className={styles.pointLink}>{translation.footer.top.myAccount["6"]}</div> */}
                    </div>
                    <div className={styles.itemWrapper}>
                        <div className={styles.title}>{translationMix(['footer', 'top', 'stayUpdated', 'title'])}</div>
                        <a href={`https://www.facebook.com/bizserver`} rel={"noreferrer"} target={"_blank"} className={styles.pointLink}>{translationMix(['footer', 'top', 'stayUpdated', '1'])}</a>
                        <a href={`https://www.youtube.com/channel/UCNLS8ODebSkcVeiMATZrlOQ`} rel={"noreferrer"} target={"_blank"} className={styles.pointLink}>{translationMix(['footer', 'top', 'stayUpdated', '2'])}</a>
                        <div className={styles.itemWrapperMobile}>
                            <div className={styles.title}>{translationMix(['footer', 'top', 'contact', 'title'])}</div>
                            <div className={styles.point}><span>{translationMix(['footer', 'top', 'contact', 'address', '0'])}</span><span>{translationMix(['footer', 'top', 'contact', 'address', '1'])}</span></div>
                            <div className={styles.point}><span>{translationMix(['footer', 'top', 'contact', 'phone'])}:</span><span>+48 12 397 77 27</span></div>
                            <div className={styles.point}><span>{translationMix(['footer', 'top', 'contact', 'mail'])}:</span><span>info@bizserver.eu</span></div>
                        </div>
                    </div>
                    <div className={styles.itemWrapper}>
                        <div className={styles.title}>{translationMix(['footer', 'top', 'contact', 'title'])}</div>
                        <div className={styles.point}><span>Bizserver, Albatrosów 1,</span><span>30-716 {translationMix(['footer', 'top', 'contact', 'address','0'])}, {translationMix(['footer', 'top', 'contact', 'address','1'])}</span></div>
                        <div className={styles.point}><span>{translationMix(['footer', 'top', 'contact', 'phone'])}:</span><span>+48 12 397 77 27</span></div>
                        <div className={styles.point}><span>{translationMix(['footer', 'top', 'contact', 'mail'])}:</span><span>info@bizserver.eu</span></div>
                    </div>
                </div>
                <div className={styles.arrowUp} onClick={() => scrollToTop(0, 1000)} />
            </div>
            <div className={styles.bottom}>
                <div className={styles.bottomLogo} />
                <div className={styles.bottomText}>{translationMix(['footer', 'bottom'])}</div>
            </div>
        </div>
    )
}

export default Footer;