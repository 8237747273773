import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import styles from './header.module.scss'
import Menu from "../../Components/Menu/menu";
import useWindowSize from "../../Components/Helpers/useWindowSize";
import { logout } from '../../apiCalls/authApiCalls';
import { sendCurrencyChange, sendLanguageChange } from '../../apiCalls/sharedApiCalls';
import numberWithSpace from '../../Components/Helpers/numberWithSpace';
import { ThemeContext } from '../../Components/Context/context';
import stringToUrl from '../../Components/Helpers/stringToUrl';
import { translationMix } from '../../Components/Helpers/translationMix';


/**Language is setup Here
 * 
 * Here is reload if logout form user panel
*/
function Header({ cartData = null }) {
    const [timeOutCurrency, setTimeOutCurrency] = useState(false);
    const [timeOutLang, setTimeOutLang] = useState(false);
    const [timeOutCart, setTimeOutCart] = useState(false);
    const [timeOutLogin, setTimeOutLogin] = useState(false);
    const [headerCartData, setHeaderCartData] = useState(cartData);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const size = useWindowSize();
    const { lang } = useParams();
    const accountMenuRef = useRef(null);
    const cartMenuRef = useRef(null);
    const currencyMenuRef = useRef(null);
    const langMenuRef = useRef(null);
    const globalContext = useContext(ThemeContext)

    const globalContextRef = useRef(useContext(ThemeContext))

    function slideCurrencyMenu() {
        clearTimeout(timeOutCurrency)
        if (currencyMenuRef.current.style.height === '0px') {
            currencyMenuRef.current.style.height = `${currencyMenuRef.current.scrollHeight}px`;
            const myTimeOut = setTimeout(() => { currencyMenuRef.current.classList.add(styles.sliderOpen) }, 300)
            setTimeOutCurrency(myTimeOut);
        } else {
            clearTimeout(timeOutCurrency)
            currencyMenuRef.current.classList.remove(styles.sliderOpen)
            const myTimeOut = setTimeout(() => {
                currencyMenuRef.current.style.height = `0px`;
            }, 1)
            setTimeOutCurrency(myTimeOut);
        }
    }

    function changeCurrency(id) {
        localStorage.setItem("selectedCurrency", id)
        for (let i = 0; i < globalContext.allCurrecny[1].length; i++) {
            if (globalContext.allCurrecny[1][i].id === id) {
                globalContext.allCurrecny[0] = i;
                break
            }
        }
        sendCurrencyChange(id).then(res => {
            let url = window.location.href
            window.location.replace(url);
        })
    }

    function slideLanguageMenu() {
        if (langMenuRef.current.style.height === '0px') {
            clearTimeout(timeOutLang)
            langMenuRef.current.style.height = `${langMenuRef.current.scrollHeight}px`;
            const myTimeOut = setTimeout(() => { langMenuRef.current.classList.add(styles.sliderOpen) }, 300)
            setTimeOutLang(myTimeOut);
        } else {
            clearTimeout(timeOutLang)
            langMenuRef.current.classList.remove(styles.sliderOpen)
            const myTimeOut = setTimeout(() => {
                langMenuRef.current.style.height = `0px`;
            }, 1)
            setTimeOutLang(myTimeOut);
        }
    }

    function changeLanguage(value) {
        let readLang = value.toLowerCase();
        localStorage.setItem("selectedLang", readLang)
        for (let i = 0; i < globalContext.allLangApi[1].length; i++) {
            if (globalContext.allLangApi[1][i][0] === readLang) {
                globalContext.allLangApi[0] = i;
                break
            }
        }
        sendLanguageChange(globalContext.allLangApi[1][globalContext.allLangApi[0]][1]).then(res => {
            let url = window.location.pathname
            url = url.replace(lang, readLang);
            window.location.replace(window.location.origin + url);
        })


        //window.location.href = url;
        //replace dont make history in browse but href yes
    }

    function handleLogout() {
        logout().then(res => {
            setIsAuthenticated(null)
            //redirect if current page is user data page
            let currentPage = window.location.pathname.substring(lang.length + 2)
            if (currentPage === "account-address" || currentPage === "account-order" || currentPage === "account-user") {
                window.location.reload();
            } else if (currentPage === "cart") {
                window.location.href = `/${lang}`
            }
        })
    }

    function showHideAccountMenu() {
        if (!accountMenuRef.current.children[0].classList.contains(styles.heightAccountMenu)) {
            clearTimeout(timeOutLogin)
            accountMenuRef.current.classList.add(styles.showAccountMenu)

            const myTimeOut = setTimeout(() => {
                if (accountMenuRef.current)
                    accountMenuRef.current.children[0].classList.add(styles.heightAccountMenu)
            }, 1);
            setTimeOutLogin(myTimeOut)
        } else {
            clearTimeout(timeOutLogin)
            accountMenuRef.current.children[0].classList.remove(styles.heightAccountMenu)

            const myTimeOut = setTimeout(() => {
                if (accountMenuRef.current)
                    accountMenuRef.current.classList.remove(styles.showAccountMenu)
            }, 300);

            setTimeOutLogin(myTimeOut)
        }

    }

    function showHideCartMenu() {
        if (!cartMenuRef.current.children[0].classList.contains(styles.heightAccountMenu)) {
            clearTimeout(timeOutCart)
            // if (!cartMenuRef.current.classList.contains(styles.heightAccountMenu))
            cartMenuRef.current.classList.add(styles.showAccountMenu)
            const myTimeOut = setTimeout(() => {
                if (cartMenuRef.current)
                    cartMenuRef.current.children[0].classList.add(styles.heightAccountMenu)
            }, 1);
            setTimeOutCart(myTimeOut)
        }
        else {
            clearTimeout(timeOutCart)
            cartMenuRef.current.children[0].classList.remove(styles.heightAccountMenu)

            const myTimeOut = setTimeout(() => {
                if (cartMenuRef.current)
                    cartMenuRef.current.classList.remove(styles.showAccountMenu)
            }, 300);

            setTimeOutCart(myTimeOut)
        }

    }

    useEffect(() => {
        //if go on page but react dont know what langauge

        if (globalContext.allLangApi)
            if (globalContext.allLangApi[0] === -1) {
                for (let i = 0; i < globalContext.allLangApi[1].length; i++) {
                    if (lang === globalContext.allLangApi[1][i][0]) {
                        globalContext.allLangApi[0] = i;
                        break;
                    }
                }
            }
        if (localStorage.getItem('selectedLang') !== lang) {
            let readLang = lang.toLowerCase();
            localStorage.setItem("selectedLang", readLang)
            for (let i = 0; i < globalContext.allLangApi[1].length; i++) {
                if (globalContext.allLangApi[1][i][0] === readLang) {
                    globalContext.allLangApi[0] = i;
                    break
                }
            }
            sendLanguageChange(globalContext.allLangApi[1][globalContext.allLangApi[0]][1]).then(res => {
                let url = window.location.href
                url = url.replace(lang, readLang);
                window.location.replace(url);
            })
        }
        localStorage.setItem("selectedLang", lang)

        if (!cartData) {
            if (globalContext.cartData) {
                setHeaderCartData(globalContext.cartData)
            }
        }
    }, [lang, cartData, globalContext.allLangApi, globalContext.cartData])

    useEffect(() => {
        if (globalContextRef.current.authToken !== null) {
            setIsAuthenticated(globalContextRef.current.authToken)
        } else {
            setIsAuthenticated(null)
        }
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.top}>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles.call}>{translationMix(['header', 'top', 'call'])}:</div>
                        <div className={styles.icon} />
                        <div className={styles.phone}>(48) 12 397 77 27</div>
                        <Link to={`/${lang}/contact`} className={styles.contact}>{translationMix(['header', 'top', 'contact'])}</Link>
                    </div>
                    <div className={styles.right}>
                        {globalContext.allCurrecny ?
                            <div className={styles.containerMenuGlobal}>
                                <div className={styles.btnGlobal} onClick={() => slideCurrencyMenu()} >{globalContext.allCurrecny[1][globalContext.allCurrecny[0]].description}: {globalContext.allCurrecny[1][globalContext.allCurrecny[0]].code}</div>
                                <div ref={currencyMenuRef} className={styles.sliderContainer} style={{ height: '0px' }}>
                                    {globalContext.allCurrecny[1].map((item, index) => {
                                        if (globalContext.allCurrecny[0] === index) {
                                            return null
                                        } else
                                            return (<div key={index} className={styles.btnSlider} onClick={() => { changeCurrency(item.id) }}>{item.name}</div>)
                                    })}
                                </div>
                            </div> : null}
                        {globalContext.allLang ?
                            <div className={styles.containerMenuGlobal}>
                                <div className={styles.btnGlobal} onClick={() => slideLanguageMenu()} >{globalContext.allLang.description}: {globalContext.allLang.full_name}</div>
                                <div ref={langMenuRef} className={styles.sliderContainer} style={{ height: '0px' }}>
                                    {globalContext.allLang.list.map((item, index) => {
                                        return (<div key={index} className={styles.btnSlider} onClick={() => { changeLanguage(item.name) }}>{item.full_name}</div>)
                                    })}
                                </div>
                            </div> : null}
                    </div>
                </div>
            </div>
            <div className={styles.middle}>
                <div className={styles.logoWrapper}>
                    <Link to={`/${lang}`}>
                        <div className={styles.logo} />
                    </Link>
                    <div className={styles.logoText}>{translationMix(['header', 'middle', 'logoText'])}</div>
                </div>
                <form autoComplete={"off"} noValidate action={`/${lang}/search`} className={styles.inputWrapper}>
                    {size.width <= 950 ? <Menu data={globalContext.menuData} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} /> : null}
                    <input className={styles.input} name={"searchQuery"} placeholder={translationMix(['header', 'middle', 'inputPlaceholder'])} />
                    <div className={styles.loop} />
                    <button type={"submit"} className={styles.loop} aria-label="Search" />
                </form>

                <div className={styles.rightWrapper}>
                    {isAuthenticated ?
                        <div className={styles.loginContainer} >
                            <div className={styles.login} onClick={() => showHideAccountMenu()}>
                                <div className={styles.img} />
                                <div className={styles.loginText}>{translationMix(['header', 'middle', 'right', 'myAccount'])}</div>
                            </div>
                            <div ref={accountMenuRef} className={styles.parentLogin}>
                                <div className={styles.accountMenuContainer}>
                                    <div className={styles.backgroundImage} />
                                    <div className={styles.mainContainer}>
                                        <Link to={{ pathname: `/${lang}/account-user`, state: { prevPath: window.location.href } }} className={styles.btnGrey} onClick={() => { showHideAccountMenu() }}>{translationMix(['header', 'account', 'userData'])}</Link>
                                        <Link to={{ pathname: `/${lang}/account-address`, state: { prevPath: window.location.href } }} className={styles.btnGrey} onClick={() => { showHideAccountMenu() }}>{translationMix(['header', 'account', 'userAddress'])}</Link>
                                        {/* <Link to={{ pathname: `/${lang}/account-order`, state: { prevPath: window.location.href } }} className={styles.btnGrey} onClick={() => { showHideAccountMenu() }}>Moje Zamówienia</Link> */}
                                        <div className={styles.btnGrey} onClick={() => { showHideAccountMenu(); handleLogout() }}>{translationMix(['header', 'account', 'logout'])}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <Link to={{ pathname: `/${lang}/login`, state: { prevPath: window.location.href } }} className={styles.login}>
                            <div className={styles.img} />
                            <div className={styles.loginText}>{translationMix(['header', 'middle', 'right', 'loginText'])}</div>
                        </Link >
                    }
                    <div className={styles.cartContainer}>
                        <div className={styles.cart} onClick={() => showHideCartMenu()}>
                            <div className={`${styles.img} ${cartData ? cartData?.products?.length > 0 ? styles.red : '' : headerCartData ? headerCartData?.products?.length > 0 ? styles.red : '' : ''}`} >
                            </div>
                            {cartData ? cartData?.products?.length > 0 ? <div className={styles.count}>{cartData?.products?.length}</div> : null :
                                headerCartData ? headerCartData?.products?.length > 0 ? <div className={styles.count}>{headerCartData?.products?.length}</div>
                                    : null : null}
                            <div className={styles.cartText}>{translationMix(['header', 'middle', 'right', 'cartText'])}</div>
                        </div>

                        <div ref={cartMenuRef} className={styles.parent}>
                            <div className={styles.cartMenuContainer}>
                                <div className={styles.backgroundImage} />
                                <div className={styles.elemntListContainer}>
                                    {cartData ? cartData?.products?.slice(0, 3).map((item, index) => {
                                        return (
                                            <div key={index} className={styles.element}>
                                                <Link to={`/${lang}/product/${item.id_product}-${stringToUrl(item.name)}`}>
                                                    <div className={styles.img} style={{ backgroundImage: `url("${item.image}")` }}>
                                                    </div>
                                                </Link>
                                                <div className={styles.title}>{item.name}
                                                    <div className={`${item.max_quantity < 50 ? styles.amountSmall : styles.amountBig}`} />
                                                    <div className={styles.price}>{numberWithSpace(item.price_total)} {cartData.currency}</div>
                                                </div>
                                            </div>
                                        )
                                    }) : headerCartData ? headerCartData?.products?.slice(0, 3).map((item, index) => {
                                        return (
                                            <div key={index} className={styles.element}>
                                                <Link to={`/${lang}/product/${item.id_product}-${stringToUrl(item.name)}`}>
                                                    <div className={styles.img} style={{ backgroundImage: `url("${item.image}")` }}>
                                                    </div>
                                                </Link>
                                                <div className={styles.title}>{item.name}
                                                    <div className={`${item.max_quantity < 100 ? styles.amountSmall : styles.amountBig}`} />
                                                    <div className={styles.price}>{numberWithSpace(item.price_total)} {headerCartData.currency}</div>
                                                </div>
                                            </div>
                                        )
                                    }) : null

                                    }
                                </div>
                                <div className={styles.bottomContainer}>
                                    <div className={styles.sumary}>
                                        <div className={styles.text}>{translationMix(['header', 'cart', 'total'])}</div>
                                        <div className={styles.text}>{cartData ? numberWithSpace(cartData.price_total) : headerCartData ? numberWithSpace(headerCartData.price_total) : null} {cartData ? cartData.currency : headerCartData ? headerCartData.currency : null}</div>
                                    </div>
                                    <Link to={{ pathname: `/${lang}/cart`, state: { prevPath: window.location.href } }} className={styles.btnGrey} onClick={() => { showHideCartMenu() }}>{translationMix(['header', 'cart', 'btn'])}</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {size.width > 950 ?
                <div className={styles.bottom}>
                    <Menu data={globalContext.menuData} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
                </div>
                : null
            }
        </div>
    )
}

export default Header;