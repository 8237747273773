import React from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import stringToUrl from "../../Components/Helpers/stringToUrl";
import { translationMix } from "../../Components/Helpers/translationMix";
import { Loader } from "../../Components/Loader/loader";
import MenuLeftMobile from "../../Components/MenuLeftMobile/menuLeftMobile";

import styles from './information.module.scss'

function Information({ menuData }) {
    const { lang } = useParams();
    const menuId = -1;
    return (
        <>
            <Helmet>
                <title>{lang === 'pl' ? 'Informacje - Sklep internetowy Bizserver' : lang === 'en' ? 'Information - Bizserver online store' : 'Informationen - Online-Shop von Bizserver'}</title>
                <meta name="description" content={"Informacje - Sklep internetowy Bizserver"} />
            </Helmet>
            <Loader justLoad={true} />
            <div className={styles.container}>
                <MenuLeftMobile menuData={menuData} menuId={menuId} />
                <div className={styles.containerMain}>
                    <div className={styles.containerLeft}>
                        <div className={`${styles.menu}`}>
                            <div className={styles.menuTitle}>{translationMix(['header','menu','info','0'])}</div>
                            {menuData.map((item, index) => {
                                return (
                                    <div className={styles.point} key={index}>
                                        <Link to={`${stringToUrl(item[1])}`}><div className={styles.myLink}>{item[0]}</div></Link>
                                    </div>)
                            })}
                        </div>
                    </div>
                    <div className={styles.containerRight}>
                        <div className={styles.imgCert}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Information;