import React, { useContext, useEffect, useState } from "react";
import styles from "./summary.module.scss"
import sharedStyles from "../styles/cartStyles.module.scss"
// import Modal from "../../../Components/Modal/modal";
// import Adress from "../../Account/Address/address";
import { getAllProductCart, sendUserDataCart, sendNoAuthenticatedUserDataCart } from "../../../apiCalls/productApiCalls";
import numberWithSpace from "../../../Components/Helpers/numberWithSpace";
import { ThemeContext } from "../../../Components/Context/context";
import LoaderAnimation from "../../../Components/LoaderAnimation/loaderAnimation";
import { translationMix } from "../../../Components/Helpers/translationMix";

function Summary({ userData, setUserData, userSendAddress, userBillAddress, userComment, setOption, isSame, setIsUserFinish }) {
    const [isLoading, setIsLoading] = useState(false);
    // const [isModalOpened, setIsModalOpened] = useState(false)
    const [products, setProducts] = useState(null)
    const [totalPrice, setTotalPrice] = useState(null)
    const [currency, setCurrency] = useState('zł')
    const globalContext = useContext(ThemeContext)

    // const adressPopup = () => {
    //     return (
    //         <Modal opened={isModalOpened} setOpened={setIsModalOpened}>
    //             <Adress userData={userData} setUserData={setUserData} setOpened={setIsModalOpened} />
    //         </Modal>
    //     )
    // }

    function sendData() {
        setIsLoading(true)
        if (globalContext.authToken) {
            const data = { deliveryAddress: userSendAddress, billAddress: userBillAddress, comment: userComment }
            sendUserDataCart(globalContext.authToken, data).then(res => {
                setIsLoading(false)
                if (res) {
                    if (res.status) {
                        if (res.status === true) {
                            setIsUserFinish(true)
                        } else
                            setIsUserFinish(false)
                    } else
                        setIsUserFinish(false)
                } else
                    setIsUserFinish(false)
            })
        }

        if (userData) {
            // const userDataJson = {
            //     email: userData.email,
            //     gender: userData.gender,
            //     firstname: userData.firstname,
            //     lastname: userData.lastname,
            //     phone: userData.phone,
            // }

            const userDeliveryAddressJson = {
                firstname: userData.firstname,
                lastname: userData.lastname,
                company_name: userData.company_name,
                street_number: userData.street_number,
                zip_code: userData.zip_code,
                id_country: userData.id_country,
                country_name: userData.country_name,
                street: userData.street,
                apartament_number: userData.apartament_number,
                city: userData.city,
                nip: userData.nip ? userData.nip : "",
                phone: userData.phone
            }

            const userBillAddressJson = {
                firstname: userBillAddress.firstname,
                lastname: userBillAddress.lastname,
                company_name: userBillAddress.company_name,
                street_number: userBillAddress.street_number,
                zip_code: userBillAddress.zip_code,
                id_country: userBillAddress.id_country ? userBillAddress.id_country : null,
                country_name: userBillAddress.country_name,
                street: userBillAddress.street,
                apartament_number: userBillAddress.apartament_number,
                city: userBillAddress.city,
                nip: userBillAddress.nip ? userBillAddress.nip : "",
                phone: userData.phone
            }
            const data = { userData: userData, deliveryAddress: userDeliveryAddressJson, billAddress: userBillAddressJson, comment: userComment }

            sendNoAuthenticatedUserDataCart(data).then(res => {
                setIsLoading(false)
                if (res) {
                    if (res.status) {
                        if (res.status === true) {
                            setIsUserFinish(true)
                        } else
                            setIsUserFinish(false)
                    } else
                        setIsUserFinish(false)
                } else
                    setIsUserFinish(false)
            })
        } else {

        }
    }

    function goToAddress() {
        setOption(2)
    }

    useEffect(() => {
        getAllProductCart()
            .then((json) => {
                setCurrency(json.cart.currency)
                setProducts(json.cart.products)
                const jsonTmp = { price_delivery: json.cart.price_delivery, price_products_total: json.cart.price_products_total, price_total: json.cart.price_total }
                setTotalPrice(jsonTmp)
            })
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.main}>
                <p className={sharedStyles.textStrong}>{translationMix(['cart', 'summary', 'title'])}</p>
                <div className={styles.top}>
                    <div className={styles.left}>
                        <p className={sharedStyles.textStrong}>{translationMix(['cart', 'summary', 'labels', '2'])}</p>
                        <div className={styles.adress}>
                            <div className={styles.column}>
                                {userSendAddress ?
                                    <p className={sharedStyles.textSmall}>
                                        {userSendAddress.firstname} {userSendAddress.lastname} < br />
                                        {userSendAddress.company_name}<br />
                                        {userSendAddress.street} {userSendAddress.street_number}/{userSendAddress.apartament_number}< br />
                                        {userSendAddress.zip_code} {userSendAddress.city}< br />
                                        {userSendAddress.country_name}< br />
                                        {userSendAddress.phone}<br />
                                        {userSendAddress.nip}</p> : userData ?
                                        <p className={sharedStyles.textSmall}>
                                            {userData.firstname} {userData.lastname} < br />
                                            {userData.company_name ? <>{userData.company_name}< br /> </> : <></>}
                                            {userData.street} {userData.street_number}< br />
                                            {userData.zip_code} {userData.city}< br />
                                            {userData.country_name}< br />
                                            {userData.phone}</p>
                                        : <p className={sharedStyles.textSmall}></p>}
                            </div>
                            <div className={styles.column}>
                                <div className={sharedStyles.btnChange} onClick={() => { goToAddress() }}>{translationMix(['cart', 'summary', 'btn', '0'])}</div>
                                {/* <div className={sharedStyles.btnChange} onClick={() => { setIsModalOpened(!isModalOpened); }}>{translation.cart.summary.btn[0]}</div> */}
                            </div>
                        </div>
                        <p className={sharedStyles.textStrong}>{translationMix(['cart', 'summary', 'labels', '3'])}</p>
                        <div className={styles.adress}>
                            <div className={styles.column}>
                                {isSame ? userSendAddress ?
                                    <p className={sharedStyles.textSmall}>
                                        {userSendAddress.firstname} {userSendAddress.lastname} < br />
                                        {userSendAddress.company_name}<br />
                                        {userSendAddress.street} {userSendAddress.street_number}/{userSendAddress.apartament_number}< br />
                                        {userSendAddress.zip_code} {userSendAddress.city}< br />
                                        {userSendAddress.country_name}< br />
                                        {userSendAddress.phone}<br />
                                        {userSendAddress.nip}</p> :
                                    userData ?
                                        <p className={sharedStyles.textSmall}>
                                            {userData.firstname} {userData.lastname} < br />
                                            {userData.company_name ? <>{userData.company_name}< br /> </> : <></>}
                                            {userData.street} {userData.street_number}< br />
                                            {userData.zip_code} {userData.city}< br />
                                            {userData.country_name}< br />
                                            {userData.phone}</p> : <p className={sharedStyles.textSmall}></p> :
                                    userBillAddress ?
                                        <p className={sharedStyles.textSmall}>
                                            {userBillAddress.firstname} {userBillAddress.lastname} < br />
                                            {userBillAddress.company_name}<br />
                                            {userBillAddress.street} {userBillAddress.street_number}/{userBillAddress.apartament_number}< br />
                                            {userBillAddress.zip_code} {userBillAddress.city}< br />
                                            {userBillAddress.country_name}< br />
                                            {userBillAddress.phone}<br />
                                            {userBillAddress.nip}</p> :
                                        userData ?
                                            <p className={sharedStyles.textSmall}>
                                                {userData.firstname} {userData.lastname} < br />
                                                {userData.company_name ? <>{userData.company_name}< br /> </> : <></>}
                                                {userData.street} {userData.street_number}< br />
                                                {userData.zip_code} {userData.city}< br />
                                                {userData.country_name}< br />
                                                {userData.phone}</p>
                                            : <p className={sharedStyles.textSmall}></p>}
                            </div>
                            <div className={styles.column}>
                                <div className={sharedStyles.btnChange} onClick={() => { goToAddress() }}>{translationMix(['cart', 'summary', 'btn', '0'])}</div>
                            </div>
                        </div>



                    </div>
                    <div className={styles.right}>
                        <p className={sharedStyles.textSmall2}>{translationMix(['cart', 'summary', 'labels', '4'])}:</p>
                        <div className={styles.tableContainer}>
                            {products ? products.map((readProduct, index) => {
                                return (
                                    <div className={styles.item} key={index}>
                                        <div className={styles.icon} style={{ backgroundImage: `url("${readProduct.image}")` }} />
                                        <div className={styles.descriptionContainer}>
                                            <div className={sharedStyles.text}>{readProduct.name}</div>
                                            <div className={styles.ultraSmallText}>SKU: {readProduct.reference}</div>
                                        </div>
                                        <div className={styles.totalPrice}>{numberWithSpace(readProduct.price_total)} {currency}</div>
                                    </div>
                                )
                            }) : null}
                        </div>
                        <div className={styles.summary}>
                            <div className={styles.endText}>{translationMix(['cart', 'summary', 'summary', '0'])}</div>
                            <div className={sharedStyles.textMedium}>{totalPrice ? numberWithSpace(totalPrice.price_products_total) : null} {currency ? currency : null}</div>
                        </div>
                        {/* <div className={styles.summary}>
                            <div className={styles.endText}>Wysyłka (zmień)</div>
                            <div className={sharedStyles.textMedium}>{totalPrice ? totalPrice.price_delivery : null} {currency ? currency : null}</div>
                        </div> */}
                        {/*<div className={styles.summary}>
                            <div className={styles.endText}>Płatność szybki przelew</div>
                            <div className={sharedStyles.textMedium}></div>
                        </div> */}
                        <div className={styles.total}>
                            <p className={sharedStyles.textBig}>{translationMix(['cart', 'summary', 'summary', '1'])}:</p>
                            <p className={sharedStyles.textBig}>{totalPrice ? numberWithSpace(totalPrice.price_total) : null} {currency ? currency : null}</p>
                        </div>
                    </div>
                </div>
                <div className={styles.containerBottom}>
                    <div className={sharedStyles.btnGreyDark} onClick={() => sendData()}>
                        {isLoading ? <LoaderAnimation width={20} height={20} isOne={true} /> : translationMix(['cart', 'summary', 'btn', '1'])}
                    </div>
                </div>
            </div>
            {/* {adressPopup()} */}
        </div>
    )
}

export default Summary;