import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import BreadcrumbsContainer from "../../../Components/Breadcrumbs/breadcrumbs";
import scrollToTop from "../../../Components/Helpers/scrollToTop";
import LoaderAnimation from "../../../Components/LoaderAnimation/loaderAnimation";
import Create from "../../Account/Create/create";
import Login from "../../Account/Login/login";
import Cart from "../Cart/cart";
import styles from "./form.module.scss"
import sharedStyles from "../styles/cartStyles.module.scss"
import Summary from "../Summary/summary";
import useWindowSize from "../../../Components/Helpers/useWindowSize";
import CartAdress from "../CartAdress/cartAdress";
import { Loader } from "../../../Components/Loader/loader";
import { ThemeContext } from "../../../Components/Context/context";
import { Helmet } from "react-helmet";
import { translationMix } from "../../../Components/Helpers/translationMix";

function Form({ handleLogin, setCartData, cartData }) {
    const [isUserFinish, setIsUserFinish] = useState(null)
    const [isLoad, setIsLoad] = useState(false)
    const [option, setOption] = useState(0)
    const [userData, setUserData] = useState(null)
    const [adressBillSelectedId, setAdressBillSelectedId] = useState(null)
    const [adressSendSelectedId, setAdressSendSelectedId] = useState(null)
    const [userBillAddress, setUserBillAddress] = useState(null)
    const [userSendAddress, setUserSendAddress] = useState(null)
    const [isSame, setIsSame] = useState(null)
    // const [myTimeOutList, setMyTimeOutList] = useState([])
    const [userComment, setUserComment] = useState(null)
    const [userReadAddress, setUserReadAddress] = useState(null)
    const { lang } = useParams();
    const inputsRefs = useRef([])
    const loginCoverRef = useRef(null)
    const addressCoverRef = useRef(null)
    const summaryCoverRef = useRef(null)
    const size = useWindowSize()
    const globalContext = useContext(ThemeContext)

    const arrLength = 6
    if (inputsRefs.current.length !== arrLength) {
        // add or remove refs
        inputsRefs.current = Array(arrLength)
            .fill()
            .map((_, i) => inputsRefs.current[i] || createRef());
    }

    function isAuthenticated() {
        if (globalContext.authToken) {
            return true
        }
        return false
    }

    function goPrevPage() {
        window.location.href = window.history.state.state.prevPath;
    }

    function changeOption(value) {
        if (value === 0) {
            setIsLoad(true)
            setOption(value)
        } else if (value === 2) {
            if (isAuthenticated() || userData) {
                setOption(value)
            }
            else {
                setOption(1)
            }
        } else if (value === 5) {
            if (userReadAddress !== null) {
                setOption(value)
            } else if (isAuthenticated() || userData) {
                setOption(2)
            } else {
                setOption(1)
            }
        } else {
            setOption(value)
        }
    }

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let readOption = urlParams.get('opt');
        if (readOption)
            setOption(Number(readOption))
    }, [])

    useEffect(() => {
        // myTimeOutList.forEach((item) => {
        //     clearTimeout(item)
        // })
        let tmpTimeList = []

        if (inputsRefs.current[0].current) {
            //show cover if not null
            // if (loginCoverRef.current) {
            //     loginCoverRef.current.classList.add(styles.coverShow)
            //     setTimeout(() => {
            //         loginCoverRef.current.classList.add(styles.opacityFull)
            //     }, 1)
            // }
            // if (addressCoverRef.current) {
            //     addressCoverRef.current.classList.add(styles.coverShow)
            //     setTimeout(() => {
            //         addressCoverRef.current.classList.add(styles.opacityFull)
            //     }, 1)
            // }
            // if (summaryCoverRef.current) {
            //     summaryCoverRef.current.classList.add(styles.opacityFull)
            //     // summaryCoverRef.current.classList.add(styles.coverShow)
            //     setTimeout(() => {

            //         summaryCoverRef.current.classList.add(styles.coverShow)
            //     }, 1)
            // }
            //navigation 
            inputsRefs.current.forEach((item, index) => {
                if (item.current)
                    item.current.classList.remove(styles.active)
            })
            inputsRefs.current[option].current.classList.add(styles.active)
            scrollToTop(250, 1000)
            //remove covver and same extra function
            if (option === 1) {
                if (globalContext.authToken) {
                    const time = setTimeout(() => {
                        setOption(2)
                    }, 900)
                    tmpTimeList.push(time)
                }
                const time2 = setTimeout(() => {
                    if (loginCoverRef.current) {
                        loginCoverRef.current.classList.add(styles.opacityTransition)
                        const time3 = setTimeout(() => {
                            if (loginCoverRef.current)
                                loginCoverRef.current.classList.add(styles.opacityNone)
                        }, 1)
                        tmpTimeList.push(time3)
                        const time4 = setTimeout(() => {
                            if (loginCoverRef.current)
                                loginCoverRef.current.classList.add(styles.hide)
                        }, 301)
                        tmpTimeList.push(time4)
                    }
                }, 1000)
                tmpTimeList.push(time2)
            } else if (option === 2) {
                if (addressCoverRef.current) {
                    const time = setTimeout(() => {
                        if (addressCoverRef.current) {
                            addressCoverRef.current.classList.add(styles.opacityTransition)
                            const time2 = setTimeout(() => {
                                if (addressCoverRef.current)
                                    addressCoverRef.current.classList.add(styles.opacityNone)
                            }, 1)
                            tmpTimeList.push(time2)
                            const time4 = setTimeout(() => {
                                if (addressCoverRef.current)
                                    addressCoverRef.current.classList.add(styles.hide)
                            }, 301)
                            tmpTimeList.push(time4)
                        }
                    }, 1000)
                    tmpTimeList.push(time)
                }
            } else if (option === 5) {
                if (summaryCoverRef.current) {
                    const time1 = setTimeout(() => {
                        if (summaryCoverRef.current) {
                            summaryCoverRef.current.classList.add(styles.opacityTransition)
                            const time2 = setTimeout(() => {
                                if (summaryCoverRef.current)
                                    summaryCoverRef.current.classList.add(styles.opacityNone)
                            }, 1)
                            tmpTimeList.push(time2)
                            const time4 = setTimeout(() => {
                                if (summaryCoverRef.current)
                                    summaryCoverRef.current.classList.add(styles.hide)
                            }, 301)
                            tmpTimeList.push(time4)
                        }
                    }, 1000)
                    tmpTimeList.push(time1)
                }
            }
            // else {
            //     if (loginCoverRef.current) {
            //         const time1 = setTimeout(() => {
            //             if (loginCoverRef.current) {
            //                 loginCoverRef.current.classList.remove(styles.opacityFull)
            //                 const time2 = setTimeout(() => {
            //                     if (loginCoverRef.current)
            //                         loginCoverRef.current.classList.remove(styles.coverShow)
            //                 }, 1000)
            //                 tmpTimeList.push(time2)
            //             }
            //         }, 1000)
            //         tmpTimeList.push(time1)
            //     }
            // }
            // setMyTimeOutList(tmpTimeList)
        }
    }, [option, globalContext.authToken])

    return (<>
        <Helmet>
            <title>{lang === 'pl' ? 'Bizserver - Twój koszyk zakupowy' : lang === 'en' ? 'Bizserver - Your shopping cart' : 'Bizserver - Dein Einkaufswagen'}</title>
            <meta name="description" content={"Bizserver - Twój koszyk zakupowy"} />
        </Helmet>
        <Loader data={cartData} scroll={false} />
        <div className={styles.container}>
            <div className={styles.main}>
                <div className={styles.breadcrumb}>
                    <BreadcrumbsContainer lang={lang} />
                </div>
                {isUserFinish === null ?
                    <>
                        <div className={styles.nav}>
                            <div ref={inputsRefs.current[0]} className={`${styles.item} ${styles.item1} ${styles.active}`} ><div className={styles.text} onClick={() => changeOption(0)}>1.{translationMix(['cart', 'form', 'labels', '0'])}</div></div>
                            <div ref={inputsRefs.current[1]} className={`${styles.item} ${styles.item2}`} ><div className={styles.text} onClick={() => changeOption(1)}>2.{translationMix(['cart', 'form', 'labels', '1'])}</div></div>
                            <div ref={inputsRefs.current[2]} className={`${styles.item} ${styles.item3}`}><div className={`${styles.text} ${isAuthenticated() || userData ? '' : styles.deactivate}`} onClick={() => changeOption(2)}>3.{translationMix(['cart', 'form', 'labels', '2'])}</div></div>
                            {/* <div ref={inputsRefs.current[3]} className={`${styles.item} ${styles.item4}`}><div className={styles.text}>4.Dostawa</div></div>
                    <div ref={inputsRefs.current[4]} className={`${styles.item} ${styles.item5}`}><div className={styles.text}>5.Płatność</div></div> */}
                            <div ref={inputsRefs.current[5]} className={`${styles.item} ${styles.item6}`} ><div className={`${styles.text} ${isAuthenticated() || userData ? '' : styles.deactivate}`} onClick={() => changeOption(5)}>4.{translationMix(['cart', 'form', 'labels', '5'])}</div></div>
                        </div>
                        {option === 0 ?
                            <Cart setOption={setOption} setCartData={setCartData} cartData={cartData} isLoadR={isLoad} /> :
                            option === 1 ?
                                <div className={styles.loginContainer}>
                                    <div ref={loginCoverRef} className={`${styles.cover1}`}>
                                        {size.width > 1200 ? <LoaderAnimation width={250} height={250} /> : size.width > 600 ? <LoaderAnimation width={180} height={180} /> : <LoaderAnimation width={130} height={130} />}
                                    </div>
                                    <Login handleLogin={handleLogin} setOption={setOption} useLoader={false} />
                                    <Create isFastForm={true} setOption={setOption} userData={userData} setUserData={setUserData} />
                                    <div className={`${sharedStyles.btnPrev}`} onClick={() => goPrevPage()}><p>{translationMix(['cart', 'form', 'btnBack', '0'])}</p><p>{translationMix(['cart', 'form', 'btnBack', '1'])}</p></div>
                                    {/* {isAuthenticated() ? <div ref={loginCoverRef} className={styles.cover2}>
                                {size.width > 1200 ? <LoaderAnimation width={250} height={250} /> : size.width > 600 ? <LoaderAnimation width={180} height={180} /> : <LoaderAnimation width={130} height={130} />}
                            </div> : null} */}
                                </div>
                                : option === 2 ? <>
                                    <div ref={addressCoverRef} className={`${styles.cover3}`}>
                                        {size.width > 1200 ? <LoaderAnimation width={250} height={250} /> : size.width > 600 ? <LoaderAnimation width={180} height={180} /> : <LoaderAnimation width={130} height={130} />}
                                    </div>
                                    <CartAdress setOption={setOption} coverRef={addressCoverRef}
                                        userData={userData} setUserData={setUserData}
                                        userComment={userComment} setUserComment={setUserComment}
                                        isSame={isSame} setIsSame={setIsSame} userReadAddress={userReadAddress} setUserReadAddress={setUserReadAddress}
                                        userSendAddress={userSendAddress} setUserSendAddress={setUserSendAddress}
                                        userBillAddress={userBillAddress} setUserBillAddress={setUserBillAddress}
                                        adressBillSelectedId={adressBillSelectedId} setAdressBillSelectedId={setAdressBillSelectedId}
                                        adressSendSelectedId={adressSendSelectedId} setAdressSendSelectedId={setAdressSendSelectedId} />

                                    <div className={`${sharedStyles.btnPrev} ${sharedStyles.btnPrevAddress}`} onClick={() => goPrevPage()}><p>{translationMix(['cart', 'form', 'btnBack', '0'])}</p><p>{translationMix(['cart', 'form', 'btnBack', '1'])}</p></div>
                                </> : option === 5 ? <>
                                    <div ref={summaryCoverRef} className={`${styles.cover4}`}>
                                        {size.width > 1200 ? <LoaderAnimation width={250} height={250} /> : size.width > 600 ? <LoaderAnimation width={180} height={180} /> : <LoaderAnimation width={130} height={130} />}
                                    </div>
                                    <Summary setOption={setOption} isSame={isSame} userComment={userComment}
                                        userSendAddress={userSendAddress} userBillAddress={userBillAddress}
                                        userData={userData} setUserData={setUserData}
                                        setIsUserFinish={setIsUserFinish} />
                                </> : null
                        }</> : isUserFinish === true ?
                        <>
                            <Loader justLoad={true} />
                            <div className={styles.endContainer}>
                                <p className={styles.text}>{translationMix(['cart', 'form', 'endGood'])}</p>
                            </div>
                        </> :
                        <>
                            <Loader justLoad={true} />
                            <div className={styles.endContainer}>
                                <p className={styles.text}>{translationMix(['cart', 'form', 'endBad'])}</p>
                            </div>
                        </>
                }
            </div>
        </div>
    </>
    )
}

export default Form;