import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../Components/Loader/loader";

import useWindowSize from "../../Components/Helpers/useWindowSize";
import styles from './blog.module.scss'

import BackgroundSlider from "../../Components/Elements/BackgroundSlider/backgroundSlider";
import BlogBigSlider from "../../Components/Sliders/Blog/blogBigSlider";
import BlogSmallSlider from "../../Components/Sliders/Blog/blogSmallSlider";
import CategorySlider from "../../Components/Sliders/Blog/categorySlider";
import Small from "../../Components/Elements/small/small";
import Medium from "../../Components/Elements/medium/medium";
import Big from "../../Components/Elements/big/big";
import Category from "../../Components/Elements/category/category";
import scrollToTop from "../../Components/Helpers/scrollToTop";
import { Helmet } from "react-helmet";
import { translationMix } from "../../Components/Helpers/translationMix";
import { getBlogMainPage } from "../../apiCalls/blogApiCalls";

function Blog() {
    const [data, setData] = useState(null)
    const [categoryId, setCategoryId] = useState(0)
    const [readLenght, setReadLenght] = useState(6)
    const listRef = useRef(null);
    const { lang } = useParams();
    const size = useWindowSize();
    const [readHeight, setReadHeight] = useState([320 * 4, 0]);

    function readMore() {
        if (readLenght === data.others_2.lenght) {
            scrollToTop(document.getElementById('listTop').offsetTop - 10, 1000)
            setTimeout(() => { setReadLenght(6) }, 500)
        }
        else
            setReadLenght(data.others_2.lenght)
    }

    useEffect(() => {
        getBlogMainPage().then(res => {
            setData(res)
        })
    }, []);

    useEffect(() => {
        if (listRef.current) {
            if (readHeight[0] === 0) {
                readHeight[0] = listRef.current.offsetHeight;
                setReadHeight([...readHeight])
                listRef.current.style.height = `${listRef.current.scrollHeight}px`
            } else if (readHeight[1] === 0) {
                readHeight[1] = listRef.current.scrollHeight
                setReadHeight([...readHeight])
                listRef.current.style.height = `${readHeight[0]}px`
            }
            else if (listRef.current.style.height === `${readHeight[0]}px`)
                listRef.current.style.height = `${readHeight[1]}px`
            else
                listRef.current.style.height = `${readHeight[0]}px`

            listRef.current.addEventListener('transitionend', () => {
                listRef.current.style.height = "auto";
            }, { once: true });
        }
    }, [readLenght, readHeight])
    return (
        <>
            <Loader data={data} />
            <Helmet>
                <title>{lang === 'pl' ? 'Bizserver - blog o najnowszych technologiach serwerowych' : lang === 'en' ? 'Bizserver - blog about the latest server technologies' : 'Bizserver - Blog über die neuesten Servertechnologien'}</title>
                <meta name="description" content={"Bizserver - blog o najnowszych technologiach serwerowych"} />
            </Helmet>
            {data ?
                <>
                    <BackgroundSlider data={data} />
                    <div className={styles.container}>
                        <div className={styles.main}>
                            <div className={styles.containerWhite}>
                                <div className={styles.content}>
                                    <div className={styles.titleContainer}>
                                        <div className={styles.title}>{translationMix(['blog', 'button', 'latest'])}</div>
                                        <div className={styles.btnContainer}>
                                            <a href={`/${lang}/blog-all`} className={styles.btnGo}>{translationMix(['blog', 'button', 'all'])}</a>
                                        </div>
                                    </div>
                                    <BlogBigSlider >
                                        {data.the_latest.map((item, index) => {
                                            return (<Big key={index} data={item} />)
                                        })}
                                    </BlogBigSlider>
                                    <div className={styles.titleContainer}>
                                        <div className={styles.title}>{translationMix(['blog', 'button', 'also'])}</div>
                                    </div>
                                    <BlogSmallSlider >
                                        {data.others.map((item, index) => {
                                            return (<Small key={index} data={item} />)
                                        })}
                                    </BlogSmallSlider>
                                </div>
                                <div className={styles.moreSpace} /></div>
                            <div className={styles.containerGrey}>
                                <div className={styles.content}>
                                    <div className={styles.titleContainer}>
                                        <div className={styles.title}>{translationMix(['blog', 'button', 'all'])}</div>
                                        <div className={styles.btnContainer}>
                                            <a href={`/`} className={styles.btnGo}>{translationMix(['blog', 'button', 'more'])}</a>
                                        </div>
                                    </div>
                                    <div className={`${styles.postContainerHorizontal} ${size.width < 1000 ? styles.extraPadding : ''}`}>
                                        <Big data={data.servers[0]} shadowLight={true} />
                                        {size.width >= 1000 ? <div className={styles.postContainerVertical}>
                                            {data.servers.map((item, index) => {
                                                return (<Small key={index} data={item} isHorizontal={true} />)
                                            })}
                                        </div> : null}
                                    </div>
                                    {size.width < 1000 ? <><div style={{ marginTop: '25px' }}></div>
                                        <BlogSmallSlider>
                                            {data.servers.map((item, index) => {
                                                return (<Small key={index} data={item} />)
                                            })}
                                        </BlogSmallSlider>
                                    </>
                                        : null}
                                    <div className={styles.titleContainer}>
                                        <div className={styles.title2}>{translationMix(['blog', 'button', 'recommended'])}</div>
                                    </div>
                                    {size.width >= 1000 ?
                                        <div className={styles.postContainerHorizontalFirstBig}>
                                            {data.recommended.map((item, index) => {
                                                if (index === 0)
                                                    return (<Medium key={index} data={item} isTextUnder={true} />)
                                                if (index === 1)
                                                    return (<Small key={index} data={item} isImageBig={true} />)
                                                if (index === 2)
                                                    return (<Small key={index} data={item} isImageBig={true} />)
                                                else
                                                    return null
                                            })}
                                        </div> :
                                        <BlogSmallSlider>
                                            {data.recommended.map((item, index) => {
                                                return (<Small key={index} data={item} />)
                                            })}
                                        </BlogSmallSlider>
                                    }
                                </div>
                                <div className={styles.moreSpace} />
                            </div>
                            <div className={styles.containerWhite}>
                                <div className={styles.content}>
                                    <div className={styles.titleContainer}>
                                        <div className={styles.title} id={"listTop"}>{translationMix(['blog', 'button', 'other'])}</div>
                                    </div>
                                    <div ref={listRef} className={styles.postList} >
                                        {data.others_2.slice(0, readLenght).map((item, index) => {
                                            return (<Medium key={index} data={item} />)
                                        })}
                                    </div>
                                    <div className={styles.btnMore} onClick={() => readMore()}>{translationMix(['blog', 'button', 'load'])}</div>
                                </div>
                                <div className={styles.moreSpace} />
                                <div className={styles.moreSpace} />
                            </div>
                            <div className={styles.containerGrey}>
                                <div className={styles.content}>
                                    <div className={styles.titleContainer}>
                                        <div className={styles.title}>{translationMix(['blog', 'button', 'category'])}</div>
                                    </div>
                                    <CategorySlider setActiveId={setCategoryId}>
                                        {data.categries.map((value, index) => {
                                            return (<Category key={index} data={value} id={index} setActiveId={setCategoryId} isActive={categoryId === index ? true : false} />)
                                        })}
                                    </CategorySlider>
                                </div>
                                <div className={styles.moreSpace} />
                            </div>
                            <div className={styles.containerWhite}>
                                <div className={styles.moreSpace} />
                                <div className={styles.content}>
                                    <div className={styles.bottom}>
                                        <div className={styles.left}>
                                            <p className={styles.title}>{translationMix(['blog', 'archives'])}</p>
                                            {data.archive?.map((itme, index) => {
                                                return (<p key={index} className={styles.description}>{itme.title}</p>)
                                            })}
                                        </div>
                                        <div className={styles.right}>
                                            <p className={styles.title}>{translationMix(['blog', 'search'])}</p>
                                            <form autoComplete={"off"} id={"thisInputSearch"} action={`/${lang}/blog-search`} method={"GET"} className={styles.inputContainer}>
                                                <input className={styles.input} type={"text"} list={"thisSearchList"} name={"searchText"} placeholder={translationMix(['shared', 'searchPlaceholder'])} />
                                                <button type={"submit"} className={styles.loop} />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.moreSpace} />
                                <div className={styles.moreSpace} />
                            </div>
                        </div>
                    </div>
                </> : null}
        </>

    )
}

export default Blog;