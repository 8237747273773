import React, { useEffect, useRef, useState } from "react";
import { translationMix } from "../Helpers/translationMix";
import styles from "./doubleRangeSlider.module.scss"

function DoubleRangeSlider({ minValue = 0, maxValue = 100, id, setMinPrice, setMaxPrice, selectedPrices = [], setSelectedPrices, currencyChar }) {
    const [isUnclick, setIsUnclikc] = useState(false);

    const sliderOneRef = useRef(null);
    const sliderTwoRef = useRef(null);

    let minGap = 0;
    let scale = 1;
    let difrence = maxValue - minValue;
    let p1 = selectedPrices.length > 0 ? Number((selectedPrices[0] - minValue) / difrence * 100) : -1;
    let p2 = selectedPrices.length > 0 ? Number((minValue + selectedPrices[1]) / difrence * 100) : -1;

    if (!maxValue || !minValue) {
        scale = 0;
    }

    if (maxValue - minValue > 5000) {
        scale = 4
        // setmaxSize(400)
    }
    else if (maxValue - minValue > 2000) {
        scale = 3

        // setmaxSize(300)
    }
    else if (maxValue - minValue > 1000) {
        scale = 2

        // setmaxSize(200)
    }

    const slideOne = (event, myId) => {
        let sliderLeft = document.querySelector(`[data-sliderleft~="${myId}"]`);
        let sliderRight = document.querySelector(`[data-sliderright~="${myId}"]`);
        if (sliderRight) {
            if (sliderRight.valueAsNumber - event.target.valueAsNumber <= minGap) {
                event.target.value = sliderRight.valueAsNumber - minGap;
            }
            let percent = ((sliderLeft.valueAsNumber) / sliderRight.max) * (1 - minValue / maxValue);
            document.querySelector(`[data-valuesamll~="${myId}"]`).textContent = Math.round(percent * maxValue + minValue);
            fillColor(myId);
        }
    }

    const slideTwo = (event, myId) => {
        let sliderLeft = document.querySelector(`[data-sliderleft~="${myId}"]`);
        let sliderRight = document.querySelector(`[data-sliderright~="${myId}"]`);
        if (sliderLeft) {
            if (event.target.valueAsNumber - sliderLeft.valueAsNumber <= minGap) {
                event.target.value = sliderLeft.valueAsNumber + minGap;
            }
            let percent = ((sliderRight.valueAsNumber) / sliderRight.max) * (1 - minValue / maxValue);
            document.querySelector(`[data-valuebig~="${myId}"]`).textContent = Math.round(percent * maxValue + minValue);
            fillColor(myId);

        }
    }

    const fillColor = (myId) => {
        let sliderLeft = document.querySelector(`[data-sliderleft~="${myId}"]`);
        let sliderRight = document.querySelector(`[data-sliderright~="${myId}"]`);
        let percent1 = ((sliderLeft.valueAsNumber) / sliderRight.max) * 100;
        let percent2 = ((sliderRight.valueAsNumber) / sliderRight.max) * 100;

        let sliderTrack = document.querySelector(`[data-slidertrack~="${myId}"]`);
        sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #d10000 ${percent1}% , #d10000 ${percent2}%, #dadae5 ${percent2}%)`;
    }

    useEffect(() => {
        //slideOne();
        //slideTwo();
        // if (maxValue - minValue > 5000) {
        //     setmaxSize(400)
        // }
        // else if (maxValue - minValue > 2000) {
        //     setmaxSize(300)
        // }
        // else if (maxValue - minValue > 1000) {
        //     setmaxSize(200)
        // } else {
        //     setmaxSize(100)
        // }
        if (selectedPrices.length > 0) {
            if (maxValue)
                setSelectedPrices([])
        }
        fillColor(id);
        //mouseUp dosnt work on mobile
        //touchend works on mobile and desktop
        //mouseUp and touchend works on Mozilla
        sliderOneRef.current.addEventListener('mouseup', () => {
            if(!isUnclick)
            setIsUnclikc(true);
            //if read max and min value from URL setIsUnclikc(false) not working so need change here
            setTimeout(() => { setIsUnclikc(false); }, 200)
        })

        sliderOneRef.current.addEventListener('touchend', () => {
            if(!isUnclick)
            setIsUnclikc(true);
            //if read max and min value from URL setIsUnclikc(false) not working so need change here
            setTimeout(() => { setIsUnclikc(false); }, 200)
        })
        sliderTwoRef.current.addEventListener('mouseup', () => {
            if(!isUnclick)
            setIsUnclikc(true);
            setTimeout(() => { setIsUnclikc(false); }, 200)
        })
        sliderTwoRef.current.addEventListener('touchend', () => {
            if(!isUnclick)
            setIsUnclikc(true);
            setTimeout(() => { setIsUnclikc(false); }, 200)
        })
    }, [id, maxValue, minValue, selectedPrices.length, setSelectedPrices, isUnclick]);

    useEffect(() => {
        if (isUnclick) {
            let percent1 = ((sliderOneRef.current.valueAsNumber) / sliderTwoRef.current.max) * (1 - minValue / maxValue);
            setMinPrice(Math.round(percent1 * maxValue + minValue))

            let percent2 = ((sliderTwoRef.current.valueAsNumber) / sliderTwoRef.current.max) * (1 - minValue / maxValue);
            setMaxPrice(Math.round(percent2 * maxValue + minValue))
            //if read max and min value from URL this now working
            setIsUnclikc(false)
        }
    }, [isUnclick, id, maxValue, minValue, setMinPrice, setMaxPrice])

    useEffect(() => {
        const sliderOne = document.querySelector(`[data-valuesamll~="${id}"]`)
        if (sliderOne && p1 !== -1) {
            sliderOne.textContent = Math.round((p1 / 100 * (maxValue - minValue)) + minValue);
        }
        const sliderTwo = document.querySelector(`[data-valuebig~="${id}"]`)
        if (sliderTwo && p2 !== -1) {
            sliderTwo.textContent = Math.round((p2 / 100 * (maxValue - minValue)) - minValue);
        }
    }, [id, maxValue, minValue, p1, p2])

    return (
        <div className={styles.wrapper}>
            <div className={styles.values}>
                <span className={styles.text1}>{translationMix(['itemList','menu','range'])}:</span>
                <span className={styles.text2} data-valuesamll={id}>{Math.round(minValue)}</span>
                <span className={styles.text2}> {currencyChar}</span>
                <span className={styles.text2}> - </span>
                <span className={styles.text2} data-valuebig={id}>{Math.round(maxValue)}</span>
                <span className={styles.text2}> {currencyChar}</span>
            </div>
            <div className={styles.container}>
                <div className={styles.sliderTrack} data-slidertrack={id}></div>
                <input ref={sliderOneRef} type="range" className={styles.button} min={0} max={100 * scale} defaultValue={p1 !== -1 ? p1 * scale : 0} data-sliderleft={id} onInput={(event) => slideOne(event, id)} />
                <input ref={sliderTwoRef} type="range" className={styles.button} min={0} max={100 * scale} defaultValue={p2 !== -1 ? p2 * scale : 100 * scale} data-sliderright={id} onInput={(event) => slideTwo(event, id)} />
            </div>
        </div>
    )
}

export default DoubleRangeSlider