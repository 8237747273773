import { apiUrls } from "../assets/api/apiUrls";

export async function getOneProduct(id) {
    //usunąć api key
    const url = `${apiUrls.mainUrl}${apiUrls.product}?id=${id}`
    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function getAllProduct(myUrl) {
    const url = myUrl
    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function getAllProductCart() {
    const url = `${apiUrls.mainUrl}cart/load`
    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function addProductCart(id, value) {
    const url = `${apiUrls.mainUrl}cart/add-product`
    const data = { id_product: id, quantity: value }
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(data)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function updateProductCart(id, value) {
    const url = `${apiUrls.mainUrl}cart/update-product`
    const data = { id_product: id, quantity: value }
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(data)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function removeProducFromCart(id) {
    const url = `${apiUrls.mainUrl}cart/delete-product`
    const data = { id_product: id }
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(data)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function sendUserDataCart(token, data) {
    const url = `${apiUrls.mainUrl}orders/add-order-user`

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(data)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function sendNoAuthenticatedUserDataCart(data) {
    const url = `${apiUrls.mainUrl}orders/add-order`

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(data)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}