import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';

import styles from './gallery.module.scss'

function Preview({ data, forwardRef, setSlideActive, showGallery }) {

    const move = (newIndex) => {
        setSlideActive(newIndex)
    }
    return (
        <Splide hasTrack={false} aria-label="..." className={styles.preview} ref={forwardRef}
            onMove={(slider, newIndex) => { move(newIndex) }}
            options={{
                perPage: 1,
                gap: '50px',
                rewind: false,
                pagination: false, //te kropki na dole
                arrows: false,
                drag: false
            }}
        >
            <SplideTrack>
                {data.map((item, index) => (
                    <SplideSlide key={index}>
                        <div className={styles.img1} style={{ backgroundImage: `url("${item.image_url}")` }} onClick={()=>{ showGallery() }}/>
                    </SplideSlide>
                ))}
            </SplideTrack>
        </Splide>
    )
}

export default Preview