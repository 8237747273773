import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Loader } from "../../Components/Loader/loader";

import styles from './noPage.module.scss'

function NoPage() {
    const { lang } = useParams();
    return (
        <>
            <Helmet>
                <title>{lang === 'pl' ? 'Błąd 404 - Sklep internetowy Bizserver' : lang === 'en' ? 'Error 404 - Bizserver online store' : 'Fehler 404 - Bizserver Online-Shop'}</title>
                <meta name="description" content={"Nie można odnaleźć strony"} />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <Loader justLoad={true} />
            <div className={styles.container}>
                <div className={styles.containerMain}>
                    <div className={styles.image} />
                </div>
            </div>
        </>
    )
}

export default NoPage;