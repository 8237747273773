import React, { useEffect, useState } from "react";
import Slider from "../../Components/Sliders/Home/Slider/slider";
import useWindowSize from "../../Components/Helpers/useWindowSize";

import { Loader } from "../../Components/Loader/loader";
import styles from './mainPage.module.scss'
import { getHomePage } from "../../apiCalls/sharedApiCalls";
import SlideUpDown from "../../Components/SlideUpDown/slideUpDown";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { translationMix } from "../../Components/Helpers/translationMix";
import stringToUrl from "../../Components/Helpers/stringToUrl";

function MainPage() {
    const [data, setData] = useState(null)
    const [sliderClose, setSliderClose] = useState(true)
    const [productType, setProductType] = useState("popular");
    const { lang } = useParams();
    const size = useWindowSize()

    useEffect(() => {
        if (!data) {
            getHomePage().then(res => {
                setData(res)
            })
        }
    }, [data]);

    const SelectProductType = () => {

        let products = data.product_popular

        if (productType === "popular") {
            products = data.product_popular
        } else if (productType === "new") {
            products = data.product_new
        } else if (productType === "mostBuy") {
            products = data.product_sale
        }

        return (
            products.map((item, index) => {
                return (
                    <div className={styles.product} key={index}>
                        <div className={styles.imageProduct} style={{ backgroundImage: `url("${item.image}")` }}></div>
                        <div className={styles.starWrapper}>
                            <div className={styles.star} />
                            <div className={styles.rating}>{item.rating.avg}</div>
                            <div className={styles.separator} />
                            <div className={styles.numbOfRatings}>{item.rating.max}</div>
                        </div>
                        <div className={styles.productName} dangerouslySetInnerHTML={{ __html: item.name }} />
                        <div className={`${styles.imageLogo} ${styles.imageMicro}`} style={{ backgroundImage: `url("${item.manufacturer.image}")` }} />
                        <div className={styles.priceNett}>{item.price} {item.currency}</div>
                        <div className={styles.priceBrutt}>{item.price_gross} {item.currency}</div>
                        <div className={styles.descriptionWrapper}>
                            <p className={styles.line}>
                                <span dangerouslySetInnerHTML={{ __html: item.description }} />
                            </p>
                            {item.attributes.length > 0 ? item.attributes.slice(0, 4).map((item, index) => {
                                return (
                                    <p className={styles.line}>
                                        <span>{item.name}:</span>
                                        <span>{item.value}</span>
                                    </p>
                                )
                            }) : null}
                        </div>
                        <div className={styles.buttons}>
                            <div className={styles.cartButton}>{translationMix(['mainPage', 'products', 'product', 'buttons', 'cart'])}</div>
                            <Link to={`/${lang}/product/${item.id}-${stringToUrl(item.name)}`} className={styles.moreInfo}>{translationMix(['mainPage', 'products', 'product', 'buttons', 'moreInfo'])}</Link>
                        </div>
                    </div>
                )
            })
        )
    }

    return (
        <>
            <Helmet>
                <title>{lang === 'pl' ? 'Bizserver - Serwery i komponenty dla firm - Sklep internetowy Bizserver' : lang === 'en' ? 'Bizserver - Servers and components for companies - Bizserver online store' : 'Bizserver - Server und Komponenten für Unternehmen - Online-Shop von Bizserver'}</title>
                <meta name="description" content={"Bizserver ⬅️ Sklep z serwerami dla firm i osób prywatnych. Oferujemy dobre i tanie serwery do biura i domu. Znajdziesz tu serwery, oprogramowanie i części do serwerów od autoryzowanego dostawcy"} />
            </Helmet>
            <Loader data={data} />
            {data ?
                <div className={styles.wrapper}>
                    <div className={styles.mainSlider}>
                        <Slider>
                            {data.slider.map((item, index) => {
                                return (
                                    <div className={styles.slideWrapper} key={index}>
                                        <div className={styles.left}>
                                            <div className={styles.img} style={{ backgroundImage: `url("${item.file_name}")` }} />
                                            {/* <div className={styles.points}>
                                                <div className={styles.point}>{translation.mainPage.slider.left["1"].points["1"]}</div>
                                                <div className={styles.point}>{translation.mainPage.slider.left["1"].points["2"]}</div>
                                            </div> */}
                                        </div>
                                        <div className={styles.right}>
                                            <div className={styles.titleGrey} dangerouslySetInnerHTML={{ __html: item.header }} />
                                            <div className={styles.titleColor} dangerouslySetInnerHTML={{ __html: item.title }} />
                                            {size.width <= 700 ?
                                                <>
                                                    <div className={styles.img} style={{ backgroundImage: `url("${item.file_name}")` }} />
                                                    {/* <div className={styles.points}>
                                                        <div className={styles.point}>{translation.mainPage.slider.left["1"].points["1"]}</div>
                                                        <div className={styles.point}>{translation.mainPage.slider.left["1"].points["2"]}</div>
                                                    </div> */}
                                                </>
                                                : null
                                            }
                                            <div className={styles.text} dangerouslySetInnerHTML={{ __html: item.description }} />
                                            <div className={`${lang === 'de' ? styles.buttonBig : styles.button}`}>{translationMix(['mainPage', 'slider', 'button'])}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                    <div className={styles.menu}>
                        <div className={styles.menuWrapper}>
                            {data.menu.map((item, index) => {
                                return (
                                    index <= 3 ?
                                        <div className={styles.menuItem} key={index}>
                                            <Link to={`/${lang}/${stringToUrl(item.name_main)}`} className={styles.title} dangerouslySetInnerHTML={{ __html: item.name }} />
                                            <div className={styles.subWrapper}>
                                                <div className={`${styles.image} ${styles.image1}`} />
                                                <div className={styles.text} dangerouslySetInnerHTML={{ __html: item.description }} />
                                                <div className={styles.list}>
                                                    {item.children.length ?
                                                        item.children.map((subItem, subIndex) => {
                                                            return (
                                                                <div className={styles.point} key={subIndex} dangerouslySetInnerHTML={{ __html: subItem.name }} />
                                                            )
                                                        })
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                )
                            })}
                        </div>
                        <SlideUpDown closed={sliderClose} closedStart={true}>
                            <div className={styles.menuWrapper}>
                                {data.menu.map((item, index) => {
                                    return (
                                        index > 3 ?
                                            <div className={styles.menuItem} key={index}>
                                                <Link to={`/${lang}/${stringToUrl(item.name_main)}`} className={styles.title} dangerouslySetInnerHTML={{ __html: item.name }} />
                                                <div className={styles.subWrapper}>
                                                    <div className={`${styles.image} ${styles.image1}`} />
                                                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: item.description }} />
                                                    <div className={styles.list}>
                                                        {item.children.length ?
                                                            item.children.map((subItem, subIndex) => {
                                                                return (
                                                                    <div className={styles.point} key={subIndex} dangerouslySetInnerHTML={{ __html: subItem.name }} />
                                                                )
                                                            })
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    )
                                })}
                            </div>
                        </SlideUpDown>

                        <div className={styles.moreCategory} onClick={() => { setSliderClose(!sliderClose) }}>{translationMix(['mainPage', 'menu', 'moreCategory'])}</div>
                    </div>
                    {/* <div className={styles.configure}>
                        <div className={styles.left}>
                            <div className={styles.top}>
                                <div className={styles.image} />
                                <div className={styles.titleWrapper}>
                                    <div className={styles.title}>{translationMix(['mainPage', 'configure', 'left', 'title'])}</div>
                                    <div className={styles.subTitle}>{translationMix(['mainPage', 'configure', 'left', 'subTitle'])}:</div>
                                </div>
                            </div>
                            <div className={styles.bottom}>
                                <div className={styles.formWrapper}>
                                    <div className={styles.line}>
                                        <div className={styles.label}>{translationMix(['mainPage', 'configure', 'left', 'height'])}</div>
                                        <input className={styles.input} placeholder={"1U"} />
                                    </div>
                                    <div className={styles.line}>
                                        <div className={styles.label}>{translationMix(['mainPage', 'configure', 'left', 'numberOfCPU'])}</div>
                                        <input className={styles.input} placeholder={"1"} />
                                    </div>
                                    <div className={styles.line}>
                                        <div className={styles.label}>{translationMix(['mainPage', 'configure', 'left', 'numberOfRAM'])}</div>
                                        <input className={styles.input} placeholder={"1"} />
                                    </div>
                                    <div className={styles.line}>
                                        <div className={styles.label}>{translationMix(['mainPage', 'configure', 'left', 'numberOfHD'])}</div>
                                        <input className={styles.input} placeholder={"1"} />
                                    </div>
                                    <div className={styles.confirm}>{translationMix(['mainPage', 'configure', 'left', 'confirm'])}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.top}>
                                {data.sections.top !== null ? <div className={styles.title} dangerouslySetInnerHTML={{ __html: data.sections.top.title }} /> : null}
                            </div>
                            <div className={styles.bottom}>
                                <div className={styles.textWrapper}>
                                    {data.sections.top !== null ? <div className={styles.text} dangerouslySetInnerHTML={{ __html: data.sections.top.description }} /> : null}
                                    <div className={styles.moreButton}>{translationMix(['mainPage', 'configure', 'right', 'moreButton'])}</div>
                                    <div className={styles.advSearch}>{translationMix(['mainPage', 'configure', 'right', 'advSearch'])}</div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className={styles.products}>
                        <div className={styles.topWrapper}>
                            <div className={styles.title}>{translationMix(['mainPage', 'products', 'title'])}</div>
                            <div className={styles.typeWrapper}>
                                <div className={`${styles.change} ${productType === 'popular' ? styles.active : ''}`} onClick={() => setProductType("popular")}>{translationMix(['mainPage', 'products', 'type', 'popular'])}</div>
                                <div className={styles.separator} />
                                <div className={`${styles.change} ${productType === 'new' ? styles.active : ''}`} onClick={() => setProductType("new")}>{translationMix(['mainPage', 'products', 'type', 'news'])}</div>
                                <div className={styles.separator} />
                                <div className={`${styles.change} ${productType === 'mostBuy' ? styles.active : ''}`} onClick={() => setProductType("mostBuy")}>{translationMix(['mainPage', 'products', 'type', 'mostBuy'])}</div>
                            </div>
                        </div>
                        <div className={styles.productsWrapper}>
                            <SelectProductType />
                        </div>
                    </div>
                    <div className={styles.workWithUs}>
                        <div className={styles.left}>
                            {data.sections.middle !== null ? <div className={styles.title} dangerouslySetInnerHTML={{ __html: data.sections.middle.title }} /> : null}
                            <div className={styles.textWrapper}>
                                {data.sections.middle !== null ? <div className={styles.text} dangerouslySetInnerHTML={{ __html: data.sections.middle.description }} /> : null}
                            </div>
                            <div className={styles.iconsWrapper}>
                                <div className={styles.iconWrapper}>
                                    <div className={styles.img1} />
                                    {data.sections.icon1 !== null ? <div className={styles.text} dangerouslySetInnerHTML={{ __html: data.sections.icon1.title }} /> : null}
                                </div>
                                <div className={styles.iconWrapper}>
                                    <div className={styles.img2} />
                                    {data.sections.icon2 ? <div className={styles.text} dangerouslySetInnerHTML={{ __html: data.sections.icon2.title }} /> : null}
                                </div>
                                <div className={styles.iconWrapper}>
                                    <div className={styles.img3} />
                                    {data.sections.icon3 ? <div className={styles.text} dangerouslySetInnerHTML={{ __html: data.sections.icon3.title }} /> : null}
                                </div>
                            </div>
                        </div>
                        <div className={styles.knowMoreContainer}>
                            <Link to={`/${lang}/information`} className={styles.knowMore}>{translationMix(['mainPage', 'workWithUs', 'btn'])}</Link>
                        </div>
                    </div>
                    <div className={styles.doYouKnow}>
                        <div className={styles.titleWrapper}>
                            <div className={styles.title}>{translationMix(['mainPage', 'doYouKnow', 'title'])}</div>
                        </div>
                        <div className={`${styles.articleWrapper} ${styles.gray}`}>
                            <div className={styles.article}>
                                <div className={styles.imageWrapper}>
                                    <div className={styles.img1} />
                                </div>
                                <div className={styles.textWrapper}>
                                    {data.sections.bottom1 !== null ? <div className={styles.title} dangerouslySetInnerHTML={{ __html: data.sections.bottom1.title }} /> : null}
                                    <div className={styles.img1} />
                                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: data.sections.bottom1?.description }} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.articleWrapper}>
                            <div className={styles.article}>
                                <div className={styles.textWrapper}>
                                    <div className={styles.title} dangerouslySetInnerHTML={{ __html: data.sections.bottom2?.title }} />
                                    <div className={styles.img2} />
                                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: data.sections.bottom2?.description }} />
                                </div>
                                <div className={styles.imageWrapper}>
                                    <div className={styles.img2} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>

    )
}

export default MainPage;