import { apiUrls } from "../assets/api/apiUrls";


export async function checkMailInDB(mail) {
    const url = `${apiUrls.mainUrl}auth/email-exists`

    const data = { email: mail };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(data)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function createAccount(data) {
    const url = `${apiUrls.mainUrl}auth/register`
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(data)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return [response, end];
}

export async function activateAccount(token) {
    const url = `${apiUrls.mainUrl}auth/activate-account?token=${token}`
    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function sendMailPasswordReset(mail) {
    const url = `${apiUrls.mainUrl}auth/send-restart-password`

    const data = { email: mail };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(data)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function sendNewPassword(readPassword, readPassword2, token) {
    const url = `${apiUrls.mainUrl}auth/password-reset?token=${token}`
    const data = { password: readPassword, password2: readPassword2 }
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(data)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function login(mail, readPassword) {
    const url = `${apiUrls.mainUrl}auth/login`
    const data = { email: mail, password: readPassword }
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify(data)
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function refreshToken() {
    const url = `${apiUrls.mainUrl}auth/refresh-token`

    const options = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function logout() {
    const url = `${apiUrls.mainUrl}auth/logout`

    const options = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}


export function get(user, url) {
    let token
    if (user && user.token) {
        token = user.token
    }
    const options = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    const promise = fetch(url, options)
    return promise
}

export function post(user, url) {
    const userString = JSON.stringify(user)
    let token
    if (user && user.token) {
        token = user.token
    }
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: userString
    }
    const promise = fetch(url, options)
    return promise
}