import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { checkMailInDB, login } from "../../../apiCalls/authApiCalls";
import { validateMailRef } from "../../../Components/Helpers/mailValidation";
import { translationMix } from "../../../Components/Helpers/translationMix";
import { Loader } from "../../../Components/Loader/loader";
import LoaderAnimation from "../../../Components/LoaderAnimation/loaderAnimation";
import styles from "./login.module.scss"

function Login({ handleLogin, setOption, useLoader = true }) {
    const [isLoading, setIsLoading] = useState(false);
    const { lang } = useParams();
    const email1 = useRef(null);
    const email2 = useRef(null);
    const password = useRef(null);
    const loginError = useRef(null);
    const loginError2 = useRef(null);
    const email1Ok = useRef(null);
    const email2Ok = useRef(null);
    const animationRef = useRef(null);
    const user = { email: '', password: '' };

    let mail = "example@bizserver.com";
    function hideText() {
        if (!animationRef.current.classList.contains(styles.hide))
            animationRef.current.classList.add(styles.hide)
    }

    function validateLogin() {
        if (validateMailRef(email2)) {
            setIsLoading(true)
            user.email = email2.current.value
            user.password = password.current.value
            user.token = 'abc'

            login(user.email, user.password).then((data) => {
                setIsLoading(false);
                if (data.status === 401) {
                    loginError.current.innerText = "* " + translationMix(['account', 'login', 'error', '1'])
                    loginError.current.classList.add(styles.red)
                    setTimeout(() => {
                        loginError.current.classList.remove(styles.red)
                    }, 3000)
                    return false;
                } else {
                    handleLogin(data)
                    if (setOption) {
                        setOption(2)
                    } else {
                        let urlTarget = window.history.state.state.prevPath.substring(window.location.origin.length).substring(lang.length + 2)
                        if (window.history.state) {
                            if (urlTarget.startsWith('account-activate') || urlTarget.startsWith('account-reset-password') || urlTarget.startsWith('account-reset')) {
                                window.location.href = "/"
                            } else {
                                window.location.href = window.history.state.state.prevPath;
                            }
                        } else
                            window.history.back()
                    }
                }
            })
            return false;
        }

        loginError.current.innerText = "* " + translationMix(['account', 'login', 'error', '1'])
        loginError.current.classList.add(styles.red)
        setTimeout(() => {
            if (loginError.current)
                loginError.current.classList.remove(styles.red)
        }, 3000)
        return false;
    }

    function checkMail(mailInputRef, okRef) {
        //animationRef.current.classList.add(styles.hide)
        if (validateMailRef(mailInputRef)) {
            if (!okRef.current.classList.contains(styles.iconShow))
                okRef.current.classList.add(styles.iconShow)
        } else
            okRef.current.classList.remove(styles.iconShow)
    }

    function checkMailExist() {
        if (validateMailRef(email1)) {
            //check mail, wait, do something
            checkMailInDB(email1.current.value).then(check => {
                if (!check) {
                    localStorage.setItem("userCorrectMail", email1.current.value)
                    window.location.href = 'account-create'
                } else {
                    loginError2.current.classList.add(styles.red)
                    setTimeout(() => {
                        loginError2.current.classList.remove(styles.red)
                    }, 3000)
                }
            })
        } else
            return false;
    }

    return (<>
        <Helmet>
            <title>{lang === 'pl' ? 'Bizserver - zaloguj się lub stwórz konto' : lang === 'en' ? 'Bizserver - Log in, or create an account' : 'Bizserver - Einloggen oder Konto erstellen'}</title>
            <meta name="description" content={"Bizserver - Aktywacja konta"} />
        </Helmet>
        {useLoader ? <Loader justLoad={true} /> : null}
        <div className={styles.container}>
            <div className={styles.main}>
                <div className={styles.vertical}>
                    <div className={styles.titleCreate}>{translationMix(['account', 'login', 'create'])}</div>
                    <div className={`${styles.vertical} ${styles.padding}`}>
                        <p className={`${styles.error}`} ref={loginError2}>{translationMix(['account', 'login', 'error', '0'])}</p>
                        <p>{translationMix(['account', 'login', 'loginLabel'])}</p>
                        <form autoComplete={"off"} noValidate onSubmit={(event) => {
                            if (!checkMailExist())
                                event.preventDefault();
                        }} action={`account-create`}>
                            <div className={styles.inputContainer}>
                                <input className={styles.email} type={"email"} required ref={email1} onInput={() => checkMail(email1, email1Ok)} onFocus={() => hideText()} />
                                <div className={styles.animationContainer} ref={animationRef}>
                                    {
                                        mail.split("").map((item, index) => {
                                            const style = { "animationDelay": (0.5 + index / 10) + "s" };
                                            return (<span key={index} style={style}>{item}</span>)
                                        })
                                    }
                                </div>
                                <div className={styles.iconOk} ref={email1Ok} />
                            </div>
                            <button type={"submit"} className={styles.btnGrey}>{translationMix(['account', 'login', 'btn', '0'])}</button>
                        </form>
                    </div>
                </div>
                <div className={styles.verticalLineContainer}>
                    <div className={styles.verticalLine}></div>
                    <div className={styles.text}>{translationMix(['account', 'login', 'or'])}</div>
                </div>

                <div className={styles.horizontalLineContainer}>
                    <div className={styles.line} />
                    <div className={styles.text}>{translationMix(['account', 'login', 'or'])}</div>
                    <div className={styles.line} />
                </div>
                <div className={styles.vertical}>
                    <div className={styles.titleLogin}>{translationMix(['account', 'login', 'login'])}</div>
                    <p className={`${styles.error} ${styles.padding}`} ref={loginError}></p>
                    <div className={`${styles.vertical} ${styles.padding}`}>
                        <form autoComplete={"off"} noValidate onSubmit={(event) => { event.preventDefault(); validateLogin(); }}>
                            <div className={styles.inputContainer}>
                                <input className={styles.email} type={"email"} placeholder={translationMix(['account', 'login', 'mail'])} required ref={email2} onInput={() => checkMail(email2, email2Ok)} />
                                <div className={styles.iconOk} ref={email2Ok} />
                            </div>
                            <div className={styles.inputContainer}>
                                <input className={styles.input} type={"password"} placeholder={translationMix(['account', 'login', 'password'])} required ref={password} />
                            </div>
                            {/* <button type={"submit"} className={styles.btnWhite}>Zaloguj</button> */}
                            <button type={"submit"} className={styles.btnWhite}>
                                {isLoading ? <LoaderAnimation width={20} height={20} isOne={true} /> : translationMix(['account', 'login', 'btn', '1'])}
                            </button>
                        </form>
                    </div>
                    <a href={`/${lang}/account-reset`} className={styles.padding}>{translationMix(['account', 'login', 'forget'])}</a>
                </div>
            </div>
        </div>
    </>
    )
}

export default Login;