import BlogSlider from '../../Sliders/Blog/backgorundSlider/blogSlider'
import styles from './background.module.scss'
import imageBackground from "../../../assets/img/Blog/imageBackground.jpg"
import { useParams } from 'react-router-dom';
import stringToUrl from '../../Helpers/stringToUrl';
import { translationMix } from '../../Helpers/translationMix';

function BackgroundSlider({ data }) {
    const { lang } = useParams();

    return (
        <BlogSlider>
            {data.slider.map((item, index) => {
                return (
                    <div className={styles.container} key={index}>
                        {index === 0 ? <div className={styles.img} style={{ backgroundImage: `url("${imageBackground}")` }}></div> :
                            <div className={styles.img} style={{ backgroundImage: `url("${item.image_url}")` }}></div>}
                        <div className={styles.shadow} />
                        <div className={styles.content}>
                            <div className={styles.title}>{item.title}</div>
                            <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.summary }}></div>
                            <a href={`/${lang}/post/${item.id}-${stringToUrl(item.title)}`} className={styles.btn}>{translationMix(['shared', 'button', 'read'])}</a>
                        </div>
                    </div>
                )
            })}
        </BlogSlider>
    )
}

export default BackgroundSlider