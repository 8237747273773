import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import '../../assets/styles/index.scss';
import stringToUrl from "../../Components/Helpers/stringToUrl";

import Header from "../Header/header";
import Footer from "../Footer/footer";
import MainPage from "../MainPage/mainPage";
import Blog from '../Blog/blog';
import Post from '../Post/post';
import Login from '../Account/Login/login';
import Create from '../Account/Create/create';
import BlogList from '../BlogList/blogList';
import PrivateRoute from '../../Components/Helpers/privateRoute';
import PasswordResetMail from '../Account/PasswordResetMail/passwordResetMail';
import { getGlobal } from '../../apiCalls/sharedApiCalls';
import BlogListAll from '../BlogListAll/blogListAll';
import ResetPassword from '../Account/ResetPassword/resetPassword';
import ItemContainer from '../Item/itemContainer';
import CartContainer from '../Cart/cartContainer';
import UserData from '../Account/UserData/userData';
import UserAddress from '../Account/UserAddress/userAddress';
import ItemListContainer from '../ItemList/itemListContainer';
import NoPage from '../NoPage/noPage';
import Policy from '../Information/Policy/policy';
import Information from '../Information/information';
import About from '../Information/About/about';
import Regulations from '../Information/Regulations/regulations';
import Contact from '../Information/Contact/contact';
import { ThemeContext } from '../../Components/Context/context';
import Payment from '../Information/Payment/payment';
import Shipping from '../Information/Shipping/shipping';
import Activate from '../Account/Activate/activate';
import { translationMix } from '../../Components/Helpers/translationMix';
// import UserOrder from '../Account/UserOrder/userOrder';

/**In useEffect get all global values
 * 
 * Need all menu to create URLs
 * 
*/
function Builder() {
    const [dataLangName, setDataLangName] = useState(["de", "en"])
    const [allUrl, setAllUrl] = useState(null)
    const menuInformationData = [
        [translationMix(['header', 'menu', 'info', '1', '0']), "about us"],
        [translationMix(['header', 'menu', 'info', '1', '1']), 'shop rules'],
        [translationMix(['header', 'menu', 'info', '1', '2']), "privacy policy"],
        [translationMix(['header', 'menu', 'info', '1', '3']), "payment methods"],
        [translationMix(['header', 'menu', 'info', '1', '4']), "product shipping"]]

    const globalContextRef = useRef(useContext(ThemeContext))

    const handleLogin = (user) => {
        globalContextRef.current.updateAuthToken(user.token)
    }

    useEffect(() => {
        getGlobal().then(res => {
            //it read new token, save it and return
            globalContextRef.current.getToken()
            globalContextRef.current.updateMenuData(res.menu)
            globalContextRef.current.updateLang(res.lang)
            let tmpLangName = [res.lang.name.toLowerCase()]
            let tmpLangApi = [[res.lang.name.toLowerCase(), res.lang.api_key]]

            res.lang.list.forEach((element) => {
                tmpLangName.push(element.name.toLowerCase())
                tmpLangApi.push([element.name.toLowerCase(), element.api_key])
            })
            setDataLangName(tmpLangName)
            globalContextRef.current.updateCart(res.cart)
            let tmpArrayLangApi = [-1, tmpLangApi]
            let myReadLang = localStorage.getItem("selectedLang")
            if (myReadLang !== null) {
                for (let i = 0; i < tmpArrayLangApi[1].length; i++) {
                    if (myReadLang === tmpArrayLangApi[1][i][0]) {
                        tmpArrayLangApi[0] = i;
                        break;
                    }
                }
            } else {
                for (let i = 0; i < tmpArrayLangApi[1].length; i++) {
                    if ('de' === tmpArrayLangApi[1][i][0]) {
                        tmpArrayLangApi[0] = i;
                        break;
                    }
                }
            }

            globalContextRef.current.updateLangApi(tmpArrayLangApi)
            let myJson = {
                id: res.currency.id,
                code: res.currency.code,
                description: res.currency.description,
                name: res.currency.name,
                symbol: res.currency.symbol
            }
            let tmpCurrency = [myJson]
            res.currency.list.forEach((element) => {
                let myJson = {
                    id: element.id,
                    code: element.code,
                    description: res.currency.description,
                    name: element.name,
                    symbol: element.symbol
                }
                tmpCurrency.push(myJson)
            })
            let tmpCurrencyArray = [0, tmpCurrency]
            let myReadCurrency = localStorage.getItem("selectedCurrency")
            if (myReadCurrency !== null) {
                for (let i = 0; i < tmpCurrencyArray[1].length; i++) {
                    if (Number(myReadCurrency) === tmpCurrencyArray[1][i].id) {
                        tmpCurrencyArray[0] = i;
                        break;
                    }
                }
            } else {
                for (let i = 0; i < tmpCurrencyArray[1].length; i++) {
                    if (tmpCurrencyArray[1][i].code === 'EUR') {
                        tmpCurrencyArray[0] = i;
                        break;
                    }
                }

            }
            globalContextRef.current.updateCurrency(tmpCurrencyArray)

            const mainUrl = `/:lang(${tmpLangName.join('|')})/`
            let allTmpUrl = []
            for (let i = 0; i < res.menu.length; i++) {
                allTmpUrl.push([`${mainUrl}${stringToUrl(res.menu[i].name_main)}`, res.menu[i].id])
                if (res.menu[i].children.length > 0) {
                    for (let j = 0; j < res.menu[i].children.length; j++) {
                        allTmpUrl.push([`${mainUrl}${stringToUrl(res.menu[i].name_main)}/${stringToUrl(res.menu[i].children[j].name_main)}`, res.menu[i].children[j].id])
                        if (res.menu[i].children[j].children.length > 0) {
                            for (let k = 0; k < res.menu[i].children[j].children.length; k++) {
                                allTmpUrl.push([`${mainUrl}${stringToUrl(res.menu[i].name_main)}/${stringToUrl(res.menu[i].children[j].name_main)}/${stringToUrl(res.menu[i].children[j].children[k].name_main)}`, res.menu[i].children[j].children[k].id])
                            }
                        }
                    }
                }
            }
            setAllUrl(allTmpUrl)
        })

    }, []);

    const App = ({ match, location }) => {
        if (allUrl && dataLangName) {
            return (
                <Suspense>
                    <Switch>
                        <Route exact path={`/:lang(${dataLangName.join('|')})`}>
                            <Header />
                            <MainPage />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/login`}>
                            <Header />
                            <Login handleLogin={handleLogin} />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/account-create`}>
                            <Header />
                            <Create handleLogin={handleLogin} />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/account-activate`}>
                            <Header />
                            <Activate />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/account-reset`}>
                            <Header />
                            <PasswordResetMail />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/account-reset-password`}>
                            <Header />
                            <ResetPassword />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/account-user`}>
                            <PrivateRoute>
                                <Header />
                                <UserData />
                                <Footer />
                            </PrivateRoute>
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/account-address`}>
                            <PrivateRoute>
                                <Header />
                                <UserAddress />
                                <Footer />
                            </PrivateRoute>
                        </Route>

                        {/* <Route exact path={`/:lang(${dataLangName.join('|')})/account-order`}>
                            <PrivateRoute>
                                <Header />
                                <UserOrder />
                                <Footer />
                            </PrivateRoute>
                        </Route> */}

                        <Route exact path={`/:lang(${dataLangName.join('|')})/cart`}>
                            <CartContainer handleLogin={handleLogin} />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/product/:id`}>
                            <ItemContainer />
                        </Route>

                        {allUrl.map((item, index) => {
                            return (
                                <Route key={index} exact path={item[0]}>
                                    <ItemListContainer myMenuId={item[1]} />
                                </Route>
                            )
                        })}

                        <Route exact path={`/:lang(${dataLangName.join('|')})/search`}>
                            <ItemListContainer isSearch={true} />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/blog`}>
                            <Header />
                            <Blog />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/blog-search`}>
                            <Header />
                            <BlogList />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/blog-all`}>
                            <Header />
                            <BlogListAll />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/post/:id`}>
                            <Header />
                            <Post />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/contact`}>
                            <Header />
                            <Contact />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/information`}>
                            <Header />
                            <Information menuData={menuInformationData} />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/${stringToUrl(menuInformationData[0][1])}`}>
                            <Header />
                            <About menuData={menuInformationData} menuIdSelected={0} />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/${stringToUrl(menuInformationData[1][1])}`}>
                            <Header />
                            <Regulations menuData={menuInformationData} menuIdSelected={1} />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/${stringToUrl(menuInformationData[2][1])}`}>
                            <Header />
                            <Policy menuData={menuInformationData} menuIdSelected={2} />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/${stringToUrl(menuInformationData[3][1])}`}>
                            <Header />
                            <Payment menuData={menuInformationData} menuIdSelected={3} />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/${stringToUrl(menuInformationData[4][1])}`}>
                            <Header />
                            <Shipping menuData={menuInformationData} menuIdSelected={4} />
                            <Footer />
                        </Route>

                        <Route exact path={`/:lang(${dataLangName.join('|')})/noPage`}>
                            <Header />
                            <NoPage />
                            <Footer />
                        </Route>

                        <Route exact path={`/`}>
                            <Redirect to={`/de`} />
                        </Route>

                        <Route exact path={`/:lang/*`}
                            render={props => (
                                dataLangName.map((item, index) => {
                                    if (props.match.params.lang === item) {
                                        return (
                                            <Redirect key={index} to={`/${props.match.params.lang}/noPage`} />
                                        )
                                    } else if (index === dataLangName.length - 1) {
                                        return (
                                            <Redirect key={index} to={`/pl/noPage`} />
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            )}
                        />

                        <Route exact path={`*`}>
                            <Redirect to={`/pl/noPage`} />
                        </Route>
                    </Switch>
                </Suspense>
            );
        }
    };

    return (
        <>
            {allUrl !== null ?
                <Router>
                    <App />
                </Router>
                : null}
        </>

    );
}

export default Builder;