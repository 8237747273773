import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { translationMix } from '../Helpers/translationMix';
import styles from './breadcrumb.module.scss'

function BreadcrumbsContainer(props) {

    const routes = [
        { path: '/pl', breadcrumb: translationMix(['breadcrumb', 'homepage']) },
        { path: '/en', breadcrumb: translationMix(['breadcrumb', 'homepage']) },
        { path: '/de', breadcrumb: translationMix(['breadcrumb', 'homepage']) },
        { path: '/', breadcrumb: null },
        { path: `/${props.lang}/cart`, breadcrumb: translationMix(['breadcrumb', 'cart']) },
        // { path: `/${props.lang}/systemy-serwerowe`, breadcrumb: translation.breadcrumb.systems },
        // { path: `/${props.lang}/serwery-storage`, breadcrumb: translation.breadcrumb.storage },
        // { path: `/${props.lang}/serwery-storage/supermicro-RACK-2u`, breadcrumb: translation.breadcrumb.supermicroRACK },
        // { path: `/${props.lang}/serwery-storage/supermicro-RACK-2u/supermicro-UP-storage`, breadcrumb: translation.breadcrumb.supermicroUP }
    ];

    function BreadcrumbsWrapper({ breadcrumbs }) {
        const Breadcrumbs = ({ breadcrumbs }) => (
            <div className={styles.wrapper}>
                {breadcrumbs.map(({ match, breadcrumb }) => (
                    breadcrumb === breadcrumbs[breadcrumbs.length - 1].breadcrumb
                        ? <div key={match.url} className={styles.lastItem}>{breadcrumb.props.children.slice(breadcrumb.props.children.split('-')[0].length + 1).split('-').join(" ").length > 0 ? 
                        breadcrumb.props.children.slice(breadcrumb.props.children.split('-')[0].length + 1).split('-').join(" ") :
                        breadcrumb.props.children}
                        </div>
                        : <div key={match.url} className={styles.breadcrumb}>
                            {breadcrumb.props.children === 'Product' ? null :
                                <>
                                    <div to={match.url}>{breadcrumb}</div>
                                    <div className={styles.separator}></div>
                                </>}
                        </div>

                ))}
            </div>
        );

        return (
            <Breadcrumbs breadcrumbs={breadcrumbs} />
        )
    }

    return withBreadcrumbs(routes)(BreadcrumbsWrapper)();
}

export default BreadcrumbsContainer;